import { makeStyles } from '@mui/styles';
import React, { useCallback, useState, useEffect } from 'react';

import { Link, Typography } from '@mui/material';
import PhotoUploadDialog from './PhotoUploadDialog';
import StatusLabel from '../StatusLabel';
import status from '../StatusLabel/status';

const imageStatusesMap = {
  yes: null,
  no: status.FAIL,
  pending: status.PROGRESS,
};

const customStatusTextMap = {
  progress: 'Pending',
};

const useStyles = makeStyles(() => ({
  photoUploaderRoot: {
    position: 'relative',
    boxShadow: `0 20px 25px -5px rgba(0,0,0,0.1),0 10px 10px -5px rgba(0,0,0,0.04)`,
    borderRadius: 15,
    overflow: 'hidden',
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  photoUploader: {
    width: 336,
  },
  photoUploaderSmall: {
    width: 168,
  },
  checkedFrame: {
    backgroundImage: `
  linear-gradient(45deg, #c5c8cb 25%, transparent 25%),
  linear-gradient(45deg, transparent 75%, #c5c8cb 75%),
  linear-gradient(45deg, transparent 75%, #c5c8cb 75%),
  linear-gradient(45deg, #c5c8cb 25%, transparent 25%)`,
    backgroundSize: '20px 20px',
    backgroundPosition: '0 0, 0 0, -10px -10px, 10px 10px',
  },
  plusImage: {
    position: 'absolute',
    height: 50,
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  withBorder: ({ small }) => ({
    border: '2px #979797 solid',
    borderRadius: 15,
    height: small ? 130 : 260,
  }),
  overlay: {
    position: 'absolute',
    height: '100%',
    backgroundColor: '#150C6488',
    cursor: 'pointer',
    opacity: 0,
    transition: '.3s opacity',
    '&:hover': {
      opacity: 1,
    },
  },
  statusLabel: {
    paddingTop: 4,
    paddingBottom: 4,
    width: '100%',
  },
  overlayText: {
    color: 'white',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
}));

const PhotoUploader = ({
  hasImage,
  setScrollPosition,
  imgUrl,
  originalImgUrl,
  imgUploadUrl,
  imgUploadUrlOriginal,
  refresh,
  small,
  selectItem,
  itemId,
  imageApprovalStatus,
  disabled = false,
  isBrands = false,
  isCategoryImage = false,
  isBrandLogo = false,
  isBrandHero = false,
  isKioskCarouselImage = false,
  menuId,
  brandId,
  enforceImageRequirements,
  aspectRatio,
}) => {
  const classes = useStyles({ small });
  const [imageLoaded, setImageLoaded] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const w = small ? 168 : 336;
  const h = small ? 126 : 252;

  const useCheckedBackground = hasImage && isBrandLogo;
  const frameClass = small ? classes.photoUploaderSmall : classes.photoUploader;
  const uploadText = small ? 'Upload' : 'Upload photo';
  const imageApprovalStatusMapped = imageStatusesMap[imageApprovalStatus];
  const isUploadable = !!itemId || isCategoryImage || isBrandLogo || isKioskCarouselImage;

  const handleCloseDialog = useCallback(
    (uploaded) => {
      setIsDialogOpen(false);
      if (uploaded && refresh) refresh();
    },
    [refresh],
  );
  const clickHandler = useCallback(() => {
    if (setScrollPosition) {
      setScrollPosition(window.scrollY);
    }
    setIsDialogOpen(true);
    return selectItem ? selectItem() : null;
  }, [selectItem, setScrollPosition]);

  useEffect(() => {
    if (imgUrl) {
      setImageSrc(`${imgUrl}?ck=${Math.round(Math.random() * 100000)}`);
    }
  }, [imgUrl]);

  return isUploadable ? (
    <>
      <div
        onClick={clickHandler}
        onKeyUp={clickHandler}
        role="button"
        tabIndex={0}
        className={`${classes.photoUploaderRoot} ${frameClass} ${
          useCheckedBackground && classes.checkedFrame
        } ${disabled && classes.disabled}`}
      >
        {(imageLoaded === false || !hasImage) && (
          <>
            <div className={`${classes.withBorder} ${frameClass}`} />
            <img
              src="/img/uploader/plus-icon.svg"
              className={classes.plusImage}
              alt="upload"
              width={w}
              height={h}
            />
          </>
        )}
        {hasImage && imageSrc && (
          <img
            className={classes.image}
            src={imageSrc}
            alt="product"
            width={w}
            height={h}
            onLoad={() => setImageLoaded(true)}
            onError={() => setImageLoaded(false)}
            style={{ display: imageLoaded === false ? 'none' : '' }}
            loading="lazy"
          />
        )}

        <div className={`${frameClass} ${classes.overlay}`}>
          <div className={classes.overlayText}>{uploadText}</div>
        </div>

        {imageApprovalStatusMapped && (
          <div className={classes.statusLabel}>
            <StatusLabel fullWidth status={imageApprovalStatusMapped} text={customStatusTextMap} />
          </div>
        )}
      </div>

      {hasImage && originalImgUrl && (
        <div style={{ marginTop: 12 }}>
          <Link
            href={`${originalImgUrl}?ck=${Math.round(Math.random() * 100000)}`}
            target="_blank"
            rel="noreferrer"
          >
            View original image
          </Link>
        </div>
      )}

      <PhotoUploadDialog
        handleCloseDialog={handleCloseDialog}
        isOpen={isDialogOpen}
        imgUploadUrl={imgUploadUrl}
        imgUploadUrlOriginal={imgUploadUrlOriginal}
        imgUrl={imageSrc}
        itemId={itemId}
        hasImage={hasImage}
        isBrands={isBrands}
        isCategoryImage={isCategoryImage}
        isBrandLogo={isBrandLogo}
        isBrandHero={isBrandHero}
        isKioskCarouselImage={isKioskCarouselImage}
        menuId={menuId}
        brandId={brandId}
        enforceImageRequirements={enforceImageRequirements}
        aspectRatio={aspectRatio}
      />
    </>
  ) : (
    <Typography variant="body2">Please create the item first, then upload a photo</Typography>
  );
};

export default PhotoUploader;
