import React, { useState } from 'react';
import moment from 'moment';
import { TableCell, TableRow, IconButton, Tooltip, Box } from '@mui/material';
import { Delete, DirectionsBike, ShoppingBasket, Storefront } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { ReactComponent as InfoIcon } from '../../../../../assets/images/info.svg';

import VenueActions from './VenueActions';
import TableLink from '../../../../TableLink';
import DeleteAssociation from './DeleteAssociation';
import { getOtterPublishStatus, getVariationAssociationStatus } from './Status';

const useStyles = makeStyles(() => ({
  alignItems: {
    display: 'flex',
    alignItems: 'right',
  },
}));

const VenueTableRow = ({
  venue,
  handleVenueAssociation,
  handleRemoveAssociation,
  brandId,
  handleVenueMenuLink,
}) => {
  const classes = useStyles();
  const [showDeleteAssociationDialog, setShowDeleteAssociationDialog] = useState(false);
  const {
    venueId,
    venueName,
    isPickupEnabled,
    isDeliveryEnabled,
    kioskEnabled,
    variation,
    isVersionUpToDate,
    otterPublishData,
    otterPublishStatus,
    publishData,
    publishStatus,
    otterStoreId,
    menuName,
    deliveryPlatforms,
    variationId,
    deliverooStoreId,
    justeatStoreId,
    ubereatsStoreId,
  } = venue;

  const venueAssociationValues = {
    venueId,
    otterStoreId,
    otterPickupEnabled: isPickupEnabled,
    otterDeliveryEnabled: isDeliveryEnabled,
    kioskEnabled,
  };

  return (
    <>
      <TableRow key={venueId}>
        <TableCell align="left">
          <TableLink underline="always" onClick={() => handleVenueMenuLink(venueId, menuName)}>
            <Box className={classes.alignItems}>
              {venueName}{' '}
              {Object.keys(publishData).length > 0 && (
                <Tooltip
                  title={Object.entries(publishData).map(
                    ([key, { healthCheckAt, isLive }], index, array) => (
                      <Box sx={{ lineHeight: 1.5 }}>
                        <Box>
                          <strong>{key}:</strong>
                          <Box>
                            <Box>
                              Last Health Check:{' '}
                              {healthCheckAt
                                ? moment(healthCheckAt).format('YYYY-MM-DD HH:mm')
                                : 'No data'}
                            </Box>
                            <Box>Status: {isLive ? 'Taking orders' : 'Not Taking orders'}</Box>
                          </Box>
                        </Box>
                        {index < array.length - 1 && <br />}
                      </Box>
                    ),
                  )}
                  aria-label="Health check and menu status"
                >
                  <Box className={classes.alignItems} sx={{ ml: 1 }}>
                    <InfoIcon width="16px" height="16px" />
                  </Box>
                </Tooltip>
              )}
            </Box>
          </TableLink>
        </TableCell>
        <TableCell align="right">
          {isPickupEnabled && <ShoppingBasket />}
          {isDeliveryEnabled && <DirectionsBike />}
          {kioskEnabled && <Storefront />}
        </TableCell>
        <TableCell align="right">
          <Box>{variation}</Box>
        </TableCell>
        <TableCell align="left">
          <Box>{getVariationAssociationStatus({ isVersionUpToDate })}</Box>
        </TableCell>
        <TableCell align="left">
          {getOtterPublishStatus({
            otterPublishData,
            otterPublishStatus,
            platform: 'Deliveroo',
            publishData,
            publishStatus,
          })}
        </TableCell>
        <TableCell align="left">
          {getOtterPublishStatus({
            otterPublishData,
            otterPublishStatus,
            platform: 'Uber Eats',
            publishData,
            publishStatus,
          })}
        </TableCell>
        <TableCell align="left">
          {getOtterPublishStatus({
            otterPublishData,
            otterPublishStatus,
            platform: 'Just Eat',
            publishData,
            publishStatus,
          })}
        </TableCell>
        <TableCell align="right">
          <VenueActions
            venueAssociationValues={venueAssociationValues}
            menuName={menuName}
            isVersionUpToDate={isVersionUpToDate}
            handleVenueAssociation={handleVenueAssociation}
            deliveryPlatforms={deliveryPlatforms}
            brandId={brandId}
            directIds={{
              ...(deliverooStoreId && { deliverooStoreId }),
              ...(justeatStoreId && { justeatStoreId }),
              ...(ubereatsStoreId && { ubereatsStoreId }),
            }}
          />
          <IconButton
            size="small"
            style={{ marginLeft: '10px' }}
            onClick={() => setShowDeleteAssociationDialog(true)}
          >
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
      <DeleteAssociation
        venueId={venueId}
        variationId={variationId}
        venueName={venueName}
        showDialog={showDeleteAssociationDialog}
        handleCloseDialog={() => setShowDeleteAssociationDialog(false)}
        handleRemoveAssociation={handleRemoveAssociation}
      />
    </>
  );
};

export default VenueTableRow;
