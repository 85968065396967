import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import { Typography, Grid } from '@mui/material';
import { useHistory } from 'react-router';

import { getErrorMessage } from '../../../shared/utils/errors';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import Page from '../../../components/Page';
import withVenue from '../../../hoc/withVenue';
import BackArrow from '../../../components/BackArrow';
import BrandsMenuItemForm from '../../../components/Brands/BrandsMenuItemForm';
import { clearBrandsMenuItems, createBrandsMenuItem } from '../../../store/brands/brandsMenuItems';
import {
  createBuilderMenuGroupItem,
  updateBuilderMenuItems,
} from '../../../store/brands/builderMenu/builderMenuItems';

const useStyles = makeStyles(() => ({
  heading: {
    margin: '15px 0',
  },
}));

const AddBrandsMenuItem = ({ redirect }) => {
  const history = useHistory();
  const classes = useStyles();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const dispatch = useDispatch();
  const [mealDealError, setMealDealError] = useState(null);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const itemName = query.get('itemName');
  const menuName = query.get('menuName');
  const groupId = query.get('groupId');

  const handleOnSubmit = async (values) => {
    setMealDealError(null);
    try {
      const { itemId } = await dispatch(createBrandsMenuItem(values));
      if (menuName && groupId) {
        await dispatch(createBuilderMenuGroupItem({ groupId, itemId }));
        await dispatch(updateBuilderMenuItems());
      }

      showSuccessNotification(`${values.itemName} has been created successfully`);
      if (itemId) {
        history.push(`/brand-menu-management/item/${itemId}`);
      } else {
        redirect();
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.includes('cannot be used in a meal deal')) {
        setMealDealError(errorMessage);
      }
      showErrorNotification(errorMessage);
    }
  };

  return (
    <Page>
      <header>
        <BackArrow redirect={redirect} text="Brand Items" />
        <Typography className={classes.heading} variant="h2" component="h1">
          Create Item
        </Typography>
      </header>
      <Grid container>
        <Grid item xs={12}>
          <BrandsMenuItemForm
            formAction="create"
            initialValues={{
              available: true,
              category: '',
              isAlcoholic: false,
              type: '',
              description: '',
              itemName: itemName || '',
              label: itemName || '',
              vatRate: 20,
              itemOptions: [{ optionName: '', optionPrice: '', optionCalories: undefined }],
              isMealDeal: false,
              inhouseOnly: false,
            }}
            onSubmit={handleOnSubmit}
            readOnlyFields={['available']}
            mealDealError={mealDealError}
            setMealDealError={setMealDealError}
          />
        </Grid>
      </Grid>
    </Page>
  );
};

export default withVenue(AddBrandsMenuItem, '/brand-menu-management/items', clearBrandsMenuItems);
