import React from 'react';
import { PedalBikeRounded, Storefront } from '@mui/icons-material';
import { Box } from '@mui/material';

const PayoutType = ({ type }) => {
  switch (type) {
    case 'DELIVERY':
      return (
        <Box
          sx={{
            display: 'flex',
            textWrap: 'nowrap',
            border: '1px solid #E0E0EB',
            borderRadius: '2px',
            padding: '2px 4px',
            gap: '4px',
            width: 'fit-content',
          }}
        >
          <PedalBikeRounded /> Delivery
        </Box>
      );
    case 'INHOUSE':
    default:
      return (
        <Box
          sx={{
            display: 'flex',
            textWrap: 'nowrap',
            border: '1px solid #E0E0EB',
            borderRadius: '2px',
            padding: '2px 4px',
            gap: '4px',
            width: 'fit-content',
          }}
        >
          <Storefront /> In-house
        </Box>
      );
  }
};

export default PayoutType;
