/* eslint-disable max-len */
const payoutBreakdownValues = {
  orderValue: {
    label: 'Order value (GMV)',
    tooltip:
      'Order value (GMV) includes VAT, and is after any discounts but before refunds. It excludes service charges. Orders paid for using vouchers are included in this total, but purchases of vouchers are not included here.',
  },
  sessionsFees: {
    label: 'Sessions Fees (inc VAT)',
  },
  refunds: {
    label: 'Refunds',
  },
  contestedRefunds: {
    label: 'Contested refunds',
    tooltip:
      'When your venue disputes a delivery refund and wins, the amount is retrieved from the customer and added to your payout.',
  },
  adjustments: {
    label: 'Adjustments (inc VAT)',
    tooltip:
      'Any deductions or additions to your payout that sits outside of ongoing Sessions fees. An example of a delivery payout adjustment could be an onboarding fee. An example of an in-house payout could be ordering QR codes.',
  },
  vouchers: {
    label: 'Vouchers purchased',
  },
  servicesChargesTips: {
    label: 'Service charges and tips',
    tooltip:
      'Service charge is calculated as a percentage of the order, this can be managed in settings. Tips are a fixed amount determined by the customer.',
  },
  additionalCharges: {
    label: 'Additional charges',
    tooltip: 'A customisable fixed charge you can turn on for customers e.g. Donate to charity.',
  },
  cashPayments: {
    label: 'Cash payments',
    tooltip:
      'Orders paid with cash or processed via a sales account on epos (so Serve has not processed the funds).',
  },
  deliveryFees: {
    label: 'Delivery fees',
    tooltip:
      'A percentage fee applied when customers order Sessions items through delivery apps. These can vary depending on which delivery app is used, delivery method (i.e. platform drivers or own drivers) or the Sessions brand being sold e.g. SoBe burger, Patty Guy.',
  },
  deliveryPickupFees: {
    label: 'Delivery pickup fees',
    tooltip:
      'A percentage fee applied when customers order Sessions items through delivery apps and then pickup from your venue. These can vary depending on which delivery app is used or the Sessions brand being sold e.g. SoBe burger, Patty Guy.',
  },
  transactionFees: {
    label: 'Transaction fees',
    tooltip:
      'Card processing fees consist of a fixed charge per transaction and a percentage per the order value. The percentage amount may vary depending on the type of card network e.g. Visa or Amex.',
  },
  franchiseMarketingFees: {
    label: 'Franchise marketing fees',
    tooltip:
      'Franchise marketing fee is a percentage fee applied as a contribution towards marketing the brand. Depending on your contract and setup, this will apply to only Sessions branded items or to all items you sell.',
  },
  inhouseSessionsItems: {
    label: 'In-house sales: Sessions branded items',
    tooltip:
      'Percentage fee applied to Sessions branded food and drink items that are sold via in-house channels such as Epos, QR, QR takeaway, Sessions App and Kiosk.',
  },
  inhouseItems: {
    label: 'In-house sales: Non-Sessions items',
    tooltip:
      'Percentage fee applied to non-Sessions branded food and drink items that are sold via in-house channels such as Epos, QR, QR takeaway, Sessions App and Kiosk.',
  },
  total: {
    label: 'Total',
  },
};

export default payoutBreakdownValues;
