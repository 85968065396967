import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { pick } from 'ramda';

import { makeStyles } from '@mui/styles';
import { Chip, Grid, Paper, Typography, IconButton } from '@mui/material';
import { MdClose } from 'react-icons/md';

import { getErrorMessage } from '../../../shared/utils/errors';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';

import {
  fetchBrandsMenuItem,
  updateBrandsMenuItem,
  clearBrandsMenuItem,
  clearBrandsMenuItems,
} from '../../../store/brands/brandsMenuItems';
import { getBrandsMenuItemState } from '../../../store/brands/brandsMenuItems/selectors';
import { fetchBrandsIngredients } from '../../../store/brands/brandsIngredients';
import { getBrandsIngredientsState } from '../../../store/brands/brandsIngredients/selectors';
import { fetchBrandsModifiers } from '../../../store/brands/brandsModifiers';

import Page from '../../../components/Page';
import BrandsMenuItemForm from '../../../components/Brands/BrandsMenuItemForm';
import BrandsIngredientsList from '../../../components/Brands/BrandsIngredientsList';
import withVenue from '../../../hoc/withVenue';
import shouldLoad from '../../../shared/utils/shouldLoad';
import { getModifiersState } from '../../../store/modifiers/selectors';
import BackArrow from '../../../components/BackArrow';
import PageHeader from '../../../components/PageHeader';
import BrandsModifiersList from '../../../components/Brands/BrandsModifiersList';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  heading: {
    margin: '15px 0',
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: '10px',
  },
}));

const buildInitialValues = (localMenuItem) => ({
  ...{ dietaryRequirements: [] },
  ...pick(
    [
      'brandName',
      'brandId',
      'label',
      'imgUrl',
      'originalImgUrl',
      'imgUploadUrl',
      'imgOriginalUploadUrl',
      'thumbUrl',
      'thumbUploadUrl',
      'vatRate',
      'itemName',
      'itemId',
      'itemOptions',
      'category',
      'type',
      'description',
      'available',
      'isAlcoholic',
      'inhouseOnly',
      'hidden',
      'dietaryRequirements',
      'hasImage',
      'isMealDeal',
      'imageApprovalStatus',
      'snoozeUntil',
      'readonly',
    ],
    localMenuItem,
  ),
});

const BrandsMenuItem = ({ redirect, itemId, showCloseButton = false, handleDialogOpen }) => {
  const params = useParams();
  const menuItemId = params.menuItemId || itemId;
  const classes = useStyles();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const dispatch = useDispatch();

  const [loadRefresh, setLoadRefresh] = useState(false);
  const [mealDealError, setMealDealError] = useState(null);
  const menuItemState = useSelector(getBrandsMenuItemState);
  const ingredientsState = useSelector(getBrandsIngredientsState);
  const modifiersState = useSelector(getModifiersState);
  const { loading: loadingItem, data: menuItem, error: errorItem } = menuItemState;
  const {
    loading: loadingIngredients,
    data: ingredients,
    error: errorIngredients,
  } = ingredientsState;

  const initialValues = useMemo(() => buildInitialValues(menuItem || {}), [menuItem]);

  useEffect(() => {
    if (shouldLoad(ingredientsState)) dispatch(fetchBrandsIngredients());
    if (shouldLoad(menuItemState)) dispatch(fetchBrandsMenuItem(menuItemId));
  }, [dispatch, ingredientsState, menuItemId, menuItemState]);

  useEffect(() => {
    if (shouldLoad(modifiersState)) dispatch(fetchBrandsModifiers());
  }, [dispatch, modifiersState]);

  const refresh = useCallback(async () => {
    await dispatch(fetchBrandsMenuItem(menuItemId));
    setLoadRefresh(false);
  }, [menuItemId, dispatch]);

  const refreshItem = useCallback(() => {
    setLoadRefresh(true);
    setTimeout(refresh, 2000);
  }, [refresh]);

  const handleReorderOptions = async (values) => {
    try {
      await dispatch(updateBrandsMenuItem({ menuItemId, values }));
      showSuccessNotification('The list has been re-ordered successfully');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const handleOnSubmit = async (values) => {
    setMealDealError(null);
    try {
      const valuesWithModifiers = { ...values, modifiers: menuItem.modifiers };
      await dispatch(updateBrandsMenuItem({ menuItemId, values: valuesWithModifiers }));
      showSuccessNotification('Menu Item has been updated successfully');
      await dispatch(clearBrandsMenuItems());
      redirect();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.includes('cannot be used in a meal deal')) {
        setMealDealError(errorMessage);
      }
      showErrorNotification(errorMessage);
    }
  };

  return (
    <div className={showCloseButton ? classes.container : ''}>
      <PageHeader fullWidth>
        {showCloseButton ? (
          <IconButton onClick={handleDialogOpen} size="small">
            <MdClose />
          </IconButton>
        ) : (
          <BackArrow to="/brand-menu-management/items" text="Brand Items" />
        )}
        {menuItem && (
          <Typography className={classes.heading} variant="h2" component="h1">
            {`${menuItem.category}: ${menuItem.itemName}`}
            {menuItem.readonly && <Chip label="Read-only" />}
          </Typography>
        )}
      </PageHeader>
      <Page
        loading={loadingItem || loadingIngredients || loadRefresh}
        error={errorItem || errorIngredients}
        fullWidth
      >
        {menuItem && ingredients && (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                <BrandsMenuItemForm
                  initialValues={initialValues}
                  onSubmit={handleOnSubmit}
                  onReorderOptions={handleReorderOptions}
                  refreshItem={refreshItem}
                  readOnlyFields={['brandName']}
                  mealDealError={mealDealError}
                  setMealDealError={setMealDealError}
                  enforceImageRequirements
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper className={classes.paper}>
                  <BrandsModifiersList menuItemId={menuItemId} readonly={menuItem.readonly} />
                </Paper>
                <Paper className={classes.paper}>
                  <BrandsIngredientsList menuItemId={menuItemId} readonly={menuItem.readonly} />
                </Paper>
              </Grid>
            </Grid>
          </>
        )}
      </Page>
    </div>
  );
};

export default withVenue(BrandsMenuItem, null, clearBrandsMenuItem);
