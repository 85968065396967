import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Link } from '@mui/material';

import { withStyles } from 'tss-react/mui';
import { makeStyles } from '@mui/styles';

import withVenue from '../../hoc/withVenue';

import Page from '../../components/Page';
import RatingsBox from './RatingsBox';
import ReviewsList from './ReviewsList';
import { clearReviews, fetchReviews, fetchReviewsPage } from '../../store/reviews';
import usePagination from '../../hooks/usePagination';
import useESPicker from '../../hooks/useESPicker';
import ESPicker from '../../components/ESPicker';
import { getReviewsState } from '../../store/reviews/selectors';
import { sortOrderEnums } from '../../shared/utils/sortOrder';
import PageHeader from '../../components/PageHeader';

const DateRangeContainer = withStyles(Box, (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'start',
    marginBottom: theme.spacing(3),

    '& .MuiGrid-item:first-child': {
      paddingLeft: 0,
    },
    '& .MuiGrid-item': {
      paddingLeft: theme.spacing(1),
    },
  },
}));

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'underline',
  },
}));

const ReviewListSize = 30;

const CustomerReviews = () => {
  const [selectedRating, setSelectedRating] = useState('');
  const dispatch = useDispatch();
  const pagination = usePagination(ReviewListSize);
  const esPicker = useESPicker({ useOrder: false, useSearch: false, useIsDelivery: true });
  const { selectedDates, dateRange, reportingType, isDelivery } = esPicker;
  const { first, requestNewPage, resetPagination } = pagination;
  const reviewsState = useSelector(getReviewsState);
  const { loading, error, data: reviewsData } = reviewsState || {};
  const { averageRating, total: totalRatings } = reviewsData || {};
  const classes = useStyles();

  const handleFetchReviewsPage = useCallback(() => {
    dispatch(
      fetchReviewsPage({
        first,
        size: ReviewListSize,
        sortOrder: sortOrderEnums.DESC,
        dateRange,
        from: selectedDates.from,
        to: selectedDates.to,
        rating: selectedRating,
        reportingType,
        isDelivery,
      }),
    );
  }, [dateRange, dispatch, first, selectedDates, selectedRating, reportingType, isDelivery]);

  useEffect(() => {
    if (requestNewPage) {
      handleFetchReviewsPage();
    }
  }, [requestNewPage, handleFetchReviewsPage]);

  useEffect(() => {
    resetPagination();

    dispatch(
      fetchReviews({
        first: 0,
        size: ReviewListSize,
        sortOrder: sortOrderEnums.DESC,
        dateRange,
        from: selectedDates.from,
        to: selectedDates.to,
        rating: selectedRating,
        reportingType,
        isDelivery,
      }),
    );
  }, [
    dateRange,
    dispatch,
    resetPagination,
    selectedDates,
    selectedRating,
    reportingType,
    isDelivery,
  ]);

  const handleOnSelectedRating = (event) => {
    setSelectedRating(event.target.value);
  };

  return (
    <>
      <PageHeader>
        <Box component="header" mb={2} display="flex">
          <Typography variant="h2" component="h1">
            Customer Reviews
          </Typography>
        </Box>
        <Box>
          <Typography paragraph>
            When a customer chooses &#x1F60D; Great or &#128578; Good, you can send them to your
            Google Maps listing to submit a review! To turn this on, add your Google Place ID{' '}
            <Link href="/settings/venue/venue-details" className={classes.link}>
              here
            </Link>
          </Typography>
          <Typography paragraph>
            As ever, you will see all reviews (including less kind ones) on this page.
          </Typography>
        </Box>
      </PageHeader>
      <Page error={error} loading={loading}>
        {reviewsData && (
          <>
            <DateRangeContainer>
              <ESPicker esPicker={esPicker} left />
            </DateRangeContainer>
            <RatingsBox
              averageRating={averageRating}
              totalRatings={totalRatings}
              dateRangeLabel={dateRange}
            />
            <ReviewsList
              loading={loading}
              reviewsData={reviewsData}
              selectedRating={selectedRating}
              handleOnSelectedRating={handleOnSelectedRating}
              pagination={pagination}
              totalRatings={totalRatings}
            />
          </>
        )}
      </Page>
    </>
  );
};

export default withVenue(CustomerReviews, clearReviews);
