import React, { useEffect, useState } from 'react';
import { Box, Chip, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

import menuTypes from '../../../shared/constants/menuTypes';
import MenuTypeIcon from './MenuTileIcon';
import SessionsBanner from './SessionsBanner';
import Actions from './Actions';
import useFetchData from '../../../hooks/useFetchEndpoint';
import DeleteMenu from './ActionModals/DeleteMenu';
import EditMenu from './ActionModals/EditMenu';
import DuplicateMenu from './ActionModals/DuplicateMenu';
import isDesktop from '../../../shared/utils/isDesktop';

const useStyles = makeStyles((theme) => ({
  menuTile: {
    display: 'flex',
    textAlign: 'left',
    flexDirection: ' column',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    minWidth: '16rem',
    maxWidth: '20rem',
    minHeight: '13.25rem',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      maxHeight: '10rem',
    },
  },
  menuTileWithBanner: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuTitle: {
    ...theme.customFonts.mediumBold,
  },
  menuTileLink: {
    textDecoration: 'none',
    '&:hover': {
      boxShadow: 'none',
      background: 'none',
    },
  },
  menuVariation: {
    ...theme.customFonts.xSmall,
    color: '#5A7296',
  },
  menuTypeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  menuType: {
    ...theme.customFonts.small,
    lineHeight: '1.43',
  },
  menuTypeInactive: {
    color: '#5A7296',
  },
  inactiveChip: {
    backgroundColor: theme.customPalette.n1,
    color: '#181C43',
    marginLeft: theme.spacing(1),
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  errorChip: {
    backgroundColor: '#CA1D2A',
    color: theme.customPalette.white,
    marginLeft: theme.spacing(1),
  },
  deliveryIcon: {
    marginRight: '4px',
  },
}));

const getErrorText = (menuStatus) => {
  const pushStatus = menuStatus?.otterPushStatus;
  const publishStatus = menuStatus?.publishStatus || menuStatus?.otterPublishStatus;
  if (pushStatus !== 'SUCCESS') {
    return 'Push error';
  }

  if (!publishStatus) {
    return 'Not published';
  }

  if (Object.values(publishStatus).some((service) => service?.status !== 'SUCCESS')) {
    return 'Publish error';
  }

  return null;
};

const truncateString = (str) => {
  if (str.length > 25) {
    return `${str.slice(0, 25)}...`;
  }
  return str;
};

const MenuTile = ({ menu, sessionsOwnedMenu = false, isAdmin = false }) => {
  const [menuStatus, setMenuStatus] = useState(null);
  const [actionModal, setActionModal] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    displayMenuName,
    brandMenuVariationName,
    type,
    active,
    kioskEnabled,
    menuName,
    otterStoreId,
    description,
    otterDeliveryEnabled,
    otterPickupEnabled,
    deliverooStoreId,
    justeatStoreId,
    ubereatsStoreId,
  } = menu;
  const isDeliveryMenu =
    type === menuTypes.DELIVERY.value || type === menuTypes.DELIVERY_INHOUSE.value;
  const classes = useStyles();
  const { data: venueMenuStatus, isLoading } = useFetchData(`/menus/${menuName}/status`);
  const { publishData = {} } = venueMenuStatus || {};

  useEffect(() => {
    if (venueMenuStatus) {
      setMenuStatus(getErrorText(venueMenuStatus));
    }
  }, [venueMenuStatus]);

  return (
    <>
      <Link to={`/menus/${menuName}`} className={classes.menuTileLink}>
        <Paper className={classes.menuTile}>
          <Box className={sessionsOwnedMenu && classes.menuTileWithBanner}>
            <Box>
              <Typography variant="h3" className={classes.menuTitle}>
                {truncateString(displayMenuName)}
              </Typography>
              {isAdmin && brandMenuVariationName && (
                <Typography variant="body2" className={classes.menuVariation}>
                  {brandMenuVariationName && brandMenuVariationName}
                </Typography>
              )}
              <Box className={classes.menuTypeContainer}>
                <MenuTypeIcon type={type} isActive={active} />
                <Typography
                  variant="body2"
                  className={`${classes.menuType} ${!active && classes.menuTypeInactive}`}
                >
                  {menuTypes[type].label}
                </Typography>
                {!active && (
                  <Chip
                    label={isDeliveryMenu ? 'Paused' : 'Inactive'}
                    size="small"
                    className={classes.inactiveChip}
                  />
                )}
              </Box>
              {kioskEnabled && (
                <Box className={classes.menuTypeContainer} sx={{ mt: 1 }}>
                  <MenuTypeIcon type={menuTypes.KIOSK.value} isActive />
                  <Typography variant="body2" className={`${classes.menuType}`}>
                    {menuTypes.KIOSK.label}
                  </Typography>
                </Box>
              )}
            </Box>
            {sessionsOwnedMenu && <SessionsBanner />}
          </Box>
          <Box
            className={classes.actionContainer}
            sx={{
              '& div:last-child': {
                marginLeft: 'auto',
              },
            }}
          >
            <Actions
              isSessionsMenu={sessionsOwnedMenu}
              setActionModal={setActionModal}
              actionModal={actionModal}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
            />
            {isAdmin && otterStoreId && !isLoading && venueMenuStatus && menuStatus !== null && (
              <Chip label={menuStatus} size="small" className={classes.errorChip} />
            )}
            <Box>
              {Object.entries(publishData).map(([key, { isLive }]) => (
                <>
                  {isLive ? (
                    <img
                      width={isDesktop ? '24px' : '40px'}
                      height={isDesktop ? '24px' : '40px'}
                      className={classes.deliveryIcon}
                      src={`/img/delivery-Icons/${key.toLowerCase().replace(/\s+/g, '-')}-icon.svg`}
                      alt={key}
                      title={`${key} enabled`}
                    />
                  ) : (
                    <img
                      width={isDesktop ? '24px' : '40px'}
                      height={isDesktop ? '24px' : '40px'}
                      className={classes.deliveryIcon}
                      src={`/img/delivery-Icons/${key
                        .toLowerCase()
                        .replace(/\s+/g, '-')}-icon-grey.svg`}
                      alt={key}
                      title={`${key} disabled`}
                    />
                  )}
                </>
              ))}
            </Box>
          </Box>
        </Paper>
      </Link>
      <DeleteMenu
        open={actionModal === 'DELETE'}
        onClose={() => {
          setActionModal(null);
          setAnchorEl(null);
        }}
        displayMenuName={displayMenuName}
        menuName={menuName}
        active={active}
      />
      <EditMenu
        open={actionModal === 'EDIT_MENU'}
        onClose={() => {
          setActionModal(null);
          setAnchorEl(null);
        }}
        displayMenuName={displayMenuName}
        menuName={menuName}
        active={active}
        description={description}
        type={type}
        otterStoreId={otterStoreId}
        otterDeliveryEnabled={otterDeliveryEnabled}
        otterPickupEnabled={otterPickupEnabled}
        kioskEnabled={kioskEnabled}
        deliverooStoreId={deliverooStoreId}
        justeatStoreId={justeatStoreId}
        ubereatsStoreId={ubereatsStoreId}
      />
      <DuplicateMenu
        open={actionModal === 'DUPLICATE'}
        onClose={() => {
          setActionModal(null);
          setAnchorEl(null);
        }}
        displayMenuName={displayMenuName}
        menuName={menuName}
        type={type}
      />
    </>
  );
};

export default MenuTile;
