import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Button, Typography } from '@mui/material';
import { FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { clearDevices, fetchDevices } from '../../store/devices';
import { getDevicesState } from '../../store/devices/selectors';

import Page from '../../components/Page';
import DevicesTable from '../../components/DevicesTable';
import withVenue from '../../hoc/withVenue';
import { getPrintersState } from '../../store/printers/selectors';
import PrintersTable from '../../components/PrintersTable';
import { clearPrinters, fetchPrinters } from '../../store/printers';
import shouldLoad from '../../shared/utils/shouldLoad';
import useRoles from '../../hooks/useRoles';
import TerminalsTable from '../../components/TerminalsTable';
import { getTerminalDevicesState } from '../../store/terminals/selectors';
import { clearTerminalDevices, fetchTerminalDevices } from '../../store/terminals';
import PageHeader from '../../components/PageHeader';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
  },
  buttonGroup: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  heading: {
    marginBottom: '20px',
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  storeAnchor: {
    display: 'inlineBlock',
    overflow: 'hidden',
    borderRadius: '13px',
  },
  storeImage: {
    borderRadius: '13px',
    display: 'block',
    marginRight: theme.spacing(2),
  },
  printers: {
    marginTop: theme.spacing(2),
  },
}));

const Devices = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isAdmin } = useRoles();
  const devicesState = useSelector(getDevicesState);
  const printersState = useSelector(getPrintersState);
  const terminalsState = useSelector(getTerminalDevicesState);
  const { loading: devicesLoading, data: devicesData, error: devicesError } = devicesState;
  const { loading: printersLoading, data: printersData, error: printersError } = printersState;
  const {
    loading: terminalsLoading,
    data: terminalsData,
    error: terminalsError,
  } = terminalsState || {};

  useEffect(() => {
    if (shouldLoad(devicesState)) dispatch(fetchDevices());
    if (shouldLoad(printersState)) dispatch(fetchPrinters());
    if (shouldLoad(terminalsState)) dispatch(fetchTerminalDevices());
  }, [dispatch, devicesState, printersState, terminalsState]);

  return (
    <Page
      loading={devicesLoading || printersLoading || terminalsLoading}
      error={devicesError || printersError || terminalsError}
      fullWidth
    >
      {devicesData && (
        <>
          <PageHeader fullWidth>
            <div className={classes.header}>
              <Typography className={classes.title} variant="h2" component="h1">
                Devices
              </Typography>
              {isAdmin() && (
                <aside className={classes.buttonGroup}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<FiPlus />}
                    component={Link}
                    to="/devices/add"
                  >
                    Add Device
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<FiPlus />}
                    component={Link}
                    to="/printers/add"
                  >
                    Add Printer
                  </Button>
                </aside>
              )}
            </div>
          </PageHeader>
          <Typography paragraph>
            Please order devices via the{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://store.sessions.co.uk/serve-hardware"
            >
              Sessions Serve
            </a>{' '}
            Store, or contact support
          </Typography>
          <DevicesTable />
        </>
      )}
      {printersData && (
        <>
          <header className={`${classes.heading} ${classes.printers}`}>
            <Typography className={classes.title} variant="h2" component="h1">
              Printers
            </Typography>
          </header>

          <PrintersTable />
        </>
      )}

      {terminalsData && (
        <>
          <header className={`${classes.heading} ${classes.printers}`}>
            <Typography className={classes.title} variant="h2" component="h1">
              Terminals
            </Typography>
          </header>

          <TerminalsTable />
        </>
      )}
    </Page>
  );
};

export default withVenue(Devices, null, [clearDevices, clearPrinters, clearTerminalDevices]);
