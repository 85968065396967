import { makeStyles } from '@mui/styles';
import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import payoutBreakdownValues from '../../shared/constants/payoutBreakdowns';

const useStyles = makeStyles((theme) => ({
  tableBody: {
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.customPalette.n2}`,
    borderRadius: theme.spacing(1),
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
    '& .MuiTableRow-root': {
      borderBottom: `1px solid ${theme.customPalette.n2}`,
    },
    '& .MuiTableRow-root:last-child': {
      borderBottom: 'none',
    },
  },
  tableRowTitle: {
    backgroundColor: theme.customPalette.n1,
  },
  tableColumnTitle: {
    color: theme.customPalette.n6,
    ...theme.customFonts.label,
  },
  tableColumnValue: {
    ...theme.customFonts.small,
  },
  boldText: {
    fontWeight: 'bold',
  },
}));

const PayoutDetails = ({ tableType, tableData }) => {
  const classes = useStyles();
  return (
    <TableContainer
      component={Paper}
      sx={{ borderRadius: 4 }}
      className={classes.tableBody}
      elevation={0}
    >
      <Table size="small">
        <TableHead>
          <TableRow className={classes.tableRowTitle}>
            <TableCell>
              <Typography className={classes.tableColumnTitle}>
                {tableType === 'FEES' ? 'SESSIONS FEES' : 'ADJUSTMENTS'}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableColumnTitle} align="right">
                BEFORE VAT
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableColumnTitle} align="right">
                INC VAT
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map(({ value, label, exclVat, inclVat }) => {
            const isFeesTable = tableType === 'FEES';
            const computedValue = isFeesTable ? value : label;
            const isTotalRow = value === 'total' || label === 'Total';
            return (
              <TableRow key={value}>
                <TableCell
                  className={`${classes.tableColumnValue} ${isTotalRow ? classes.boldText : ''}`}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  {isFeesTable ? payoutBreakdownValues[value].label : computedValue}
                  {isFeesTable && payoutBreakdownValues[value].tooltip && (
                    <Tooltip
                      title={payoutBreakdownValues[value].tooltip}
                      sx={{ backgroundColor: '#636363' }}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            fontSize: '14px',
                            lineHeight: '24px',
                          },
                        },
                      }}
                    >
                      <InfoOutlined sx={{ marginLeft: '4px', width: '16px' }} />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell
                  className={`${classes.tableColumnValue} ${isTotalRow ? classes.boldText : ''}`}
                  align="right"
                >
                  {exclVat}
                </TableCell>
                <TableCell
                  className={`${classes.tableColumnValue} ${isTotalRow ? classes.boldText : ''}`}
                  align="right"
                >
                  {inclVat}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PayoutDetails;
