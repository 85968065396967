import { Grid, Box, Link, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import PhotoUploader from '../../components/PhotoUploader';
import { getMenuItemState } from '../../store/menuItems/selectors';
import { fetchMenuItem } from '../../store/menuItems';
import theme from '../../theme/default';

const imageApprovalDescriptionMap = {
  yes: null,
  pending: 'Images will be reviewed within 24hrs',
  no: 'Approval failed. Upload a new image',
};

const imageApprovalDescriptionColorMap = {
  yes: null,
  pending: theme.colors.indigo[6],
  no: theme.colors.red[3],
};

const useStyles = makeStyles(() => ({
  imageApprovalDescription: ({ imageApprovalStatus }) => ({
    color: imageApprovalDescriptionColorMap[imageApprovalStatus],
    fontStyle: 'italic',
  }),
}));

const ItemPhoto = ({
  item: { itemId, itemName, imgUrl, imgUploadUrl, description, hasImage, imageApprovalStatus },
  uploadSuccess,
  setScrollPosition,
  disabled = false,
  enforceImageRequirements,
}) => {
  const dispatch = useDispatch();
  const { data: menuItem } = useSelector(getMenuItemState);
  const classes = useStyles({ imageApprovalStatus });
  const itemPage = `menu-items/${itemId}`;
  const imageApprovalDescription = imageApprovalDescriptionMap[imageApprovalStatus];

  const getUploadUrl = useCallback(() => {
    if (!imgUploadUrl) {
      dispatch(fetchMenuItem(itemId));
    }
  }, [dispatch, imgUploadUrl, itemId]);

  const refresh = () => {
    uploadSuccess();
  };

  return (
    <Grid item xs={12} lg={6} className={classes.root}>
      <Box display="flex" flexWrap="nowrap" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="column" justifyContent="space-between" mr={2}>
          <Box>
            {disabled ? (
              <Typography variant="h4">{itemName}</Typography>
            ) : (
              <Link href={itemPage}>
                <Typography variant="h4">{itemName}</Typography>
              </Link>
            )}
            <Typography variant="body2">{description}</Typography>
          </Box>
          {imageApprovalDescription && (
            <Typography className={classes.imageApprovalDescription}>
              {imageApprovalDescription}
            </Typography>
          )}
        </Box>
        <Box>
          <PhotoUploader
            setScrollPosition={setScrollPosition}
            small
            disabled={disabled}
            hasImage={hasImage}
            imgUrl={imgUrl}
            originalImgUrl={menuItem?.originalImgUrl}
            imgUploadUrlOriginal={menuItem?.imgOriginalUploadUrl}
            imgUploadUrl={imgUploadUrl || menuItem?.imgUploadUrl}
            selectItem={getUploadUrl}
            refresh={refresh}
            itemId={itemId}
            imageApprovalStatus={imageApprovalStatus}
            enforceImageRequirements={enforceImageRequirements}
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default ItemPhoto;
