import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { Switch } from 'formik-mui';

import {
  Box,
  Typography,
  Card,
  IconButton,
  Chip,
  TextField,
  Grid,
  Tooltip,
  Autocomplete,
  Paper,
  InputAdornment,
} from '@mui/material';
import { MdDelete } from 'react-icons/md';
import { LoyaltyOutlined, Search } from '@mui/icons-material';
import copy from 'clipboard-copy';

import { isEqual, pick } from 'lodash';
import { makeStyles } from '@mui/styles';
import { useHistory, useParams } from 'react-router';
import OrderableTable from '../../components/OrderableTable';
import UniversalSave from '../../components/UniversalSave';
import TableButton from '../../components/TableButton/index';
import {
  clearIngredients,
  fetchIngredients,
  updateIngredient,
  updateIngredientsAvailability,
  deleteIngredient,
} from '../../store/ingredients';
import { getIngredientsState } from '../../store/ingredients/selectors';

import Page from '../../components/Page';
import withVenue from '../../hoc/withVenue';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import shouldLoad from '../../shared/utils/shouldLoad';
import CustomDialog from '../../components/CustomDialog';
import IngredientForm from '../../components/IngredientForm';
import useRoles from '../../hooks/useRoles';
import { filterDataItems } from '../../shared/utils/filterData';
import useSearch from '../../hooks/useSearch';

const useStyles = makeStyles((theme) => ({
  box: {
    borderRadius: theme.spacing(0.5),
    padding: '6px 12px 0 0',
  },
  searchBar: {
    display: 'flex',
    borderRadius: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      width: '15rem',
    },
    '& .MuiInputLabel-outlined': {
      color: '#5A7296',
      top: '-7px',
    },
    '& .MuiInputBase-input': {
      padding: '8.5px 14px',
    },
  },
  searchBox: {
    padding: theme.spacing(0.75, 0),
  },
  paragraph: {
    paddingTop: '20px',
    marginBottom: 0,
  },
  button: {
    textWrap: 'nowrap',
    paddingLeft: '10px',
    marginLeft: 'auto',
    [theme.breakpoints.up('xl')]: {
      marginLeft: 'unset',
    },
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  readonlyChip: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginTop: theme.spacing(1),
    },
  },
  dropdownWidths: {
    width: 'auto',
    minWidth: '127px',
    '& .MuiInputBase-root': {
      display: 'inline-flex',
      flexWrap: 'nowrap',
      borderRadius: '2px',
    },
  },
}));

const FormObserver = ({ setFieldValue, setValueData }) => {
  const { values, dirty } = useFormikContext();
  useEffect(() => {
    setValueData(values);
  }, [values, dirty, setFieldValue, setValueData]);
  return null;
};

const findArrayIndex = (valueData, row) => {
  if (valueData && row) {
    const index = valueData.findIndex((obj) => obj.ingredientId === `${row.ingredientId}`);
    return index;
  }
  return null;
};

const Ingredients = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ingredientsState = useSelector(getIngredientsState);
  const history = useHistory();
  const { itemId: ingredientParamId } = useParams() || {};
  const { loading, data, error } = ingredientsState;

  const ingredientTypes = ['Drink', 'Food'];
  const availabilityTypes = ['Available', 'Unavailable'];
  const initialFilters = [{ types: [], available: [] }];
  const sessionStorageFilters =
    JSON.parse(sessionStorage.getItem('ingredientsFilter')) || initialFilters;
  const [filters, setFilters] = useState(sessionStorageFilters || []);
  const [valueData, setValueData] = useState(data);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [ingredientData, setIngredientData] = useState(null);
  const [formAction, setFormAction] = useState('');
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { isAdmin } = useRoles();
  const searchKeys = useMemo(() => ['ingredientName', 'label'], []);
  const threshold = 0.2;

  const { searchResults, searchError, handleSearch, filteredItems, setFilteredItems } = useSearch(
    data,
    searchKeys,
    threshold,
    valueData,
  );

  const [initialValues, setInitialValues] = useState([]);

  const handleAvailableSwitch = useCallback(
    async (values) => {
      const changedValues = values?.filter((item, index) => !isEqual(item, initialValues[index]));

      try {
        await dispatch(updateIngredientsAvailability({ values: changedValues }));
        showSuccessNotification('Ingredient availability has been updated successfully');
      } catch (localError) {
        showErrorNotification(getErrorMessage(localError));
      }
    },
    [dispatch, initialValues, showErrorNotification, showSuccessNotification],
  );

  const handleEdit = useCallback(
    (ingredient) => {
      // When calling this function, a check is first made to see if the ingredient is an id, not an object
      if (ingredientParamId && data) {
        const ingrToUpdate = data.find((obj) => obj.ingredientId === ingredientParamId);
        setFormAction('update');
        setIngredientData(ingrToUpdate);
        setIsDialogOpen(true);
        return;
      }
      setFormAction('update');
      setIngredientData(ingredient);
      setIsDialogOpen(true);
    },
    [data, ingredientParamId],
  );

  const handleDelete = useCallback(
    async (ingredientId) => {
      try {
        await dispatch(deleteIngredient(ingredientId));
        await dispatch(fetchIngredients());
        showSuccessNotification('Ingredient has been deleted successfully');
      } catch (err) {
        showErrorNotification(getErrorMessage(err));
      }
    },
    [dispatch, showErrorNotification, showSuccessNotification],
  );

  const valueFormatter = useCallback(
    ({ value, valueName, row }) => {
      switch (valueName) {
        case 'ingredientName':
          return (
            <Box className={classes.labelContainer}>
              {row.readonly ? (
                <>
                  <Typography sx={{ mr: 1 }} variant="subtitle">
                    {value}
                  </Typography>
                  <Chip label="Read Only" size="small" className={classes.readonlyChip} />
                </>
              ) : (
                <TableButton
                  onClick={() => {
                    handleEdit(row);
                  }}
                >
                  {row.ingredientName}
                </TableButton>
              )}
            </Box>
          );
        case 'delete':
          return (
            <Box sx={isAdmin() ? { display: 'flex', justifyContent: 'flex-end' } : {}}>
              {isAdmin() && (
                <Tooltip title="Copy ingredient ID" disableInteractive>
                  <IconButton
                    size="small"
                    onClick={() => {
                      copy(row.ingredientId);
                    }}
                    sx={{ margin: '0 5px' }}
                  >
                    <LoyaltyOutlined sx={{ width: 18, height: 18 }} />
                  </IconButton>
                </Tooltip>
              )}
              <IconButton
                edge="end"
                size="small"
                onClick={() => handleDelete(row.ingredientId)}
                disabled={row?.readonly}
              >
                <MdDelete />
              </IconButton>
            </Box>
          );
        case 'available':
          if (valueData) {
            const index = findArrayIndex(valueData, row);
            const isChecked = valueData[index]?.available || false;
            return (
              <Field
                name={`[${index}].available`}
                component={Switch}
                checked={isChecked}
                value={!isChecked}
                color="primary"
                type="checkbox"
              />
            );
          }
          return null;
        default:
          return value;
      }
    },
    [classes.labelContainer, classes.readonlyChip, handleDelete, handleEdit, isAdmin, valueData],
  );

  const newData = useCallback(() => {
    const pickedData = [];
    if (filteredItems) {
      filteredItems.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item = {
          ...item,
          label: item.label || item.ingredientName,
          delete: 'delete',
        };
        pickedData.push(
          pick(item, [
            'ingredientName',
            'label',
            'available',
            'type',
            'ingredientId',
            'delete',
            'isAlcoholic',
            'readonly',
          ]),
        );
      });
    }
    return pickedData;
  }, [filteredItems]);

  const handleOnSubmit = async (values) => {
    try {
      const { ingredientId } = ingredientData;
      await dispatch(updateIngredient({ ingredientId, values }));
      if (ingredientParamId) {
        history.goBack();
        await dispatch(fetchIngredients());
        showSuccessNotification('Ingredient has been updated successfully');
        return;
      }
      await dispatch(fetchIngredients());
      showSuccessNotification('Ingredient has been updated successfully');
      setIsDialogOpen(false);
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    if (ingredientParamId) {
      history.goBack();
    }
  };

  const handleFilterChange = (_e, values, filterName) => {
    if (!values) return;

    const existingFilterData = sessionStorageFilters ? sessionStorageFilters[0] : initialFilters[0];

    const updatedFilter = [
      {
        ...existingFilterData,
        [filterName]: values || [],
      },
    ];

    setFilters(updatedFilter);
    sessionStorage.setItem('ingredientsFilter', JSON.stringify(updatedFilter));
    setFilteredItems(filterDataItems(updatedFilter, searchResults || data || []));
  };

  useEffect(() => {
    if (shouldLoad(ingredientsState)) dispatch(fetchIngredients());
    setFilteredItems(filterDataItems(filters, searchResults || data || []));
  }, [data, dispatch, filters, ingredientsState, searchResults, setFilteredItems]);

  // To check if param for ingredient id is present in URL
  useEffect(() => {
    if (ingredientParamId) {
      handleEdit(ingredientParamId);
    }
  }, [handleEdit, ingredientParamId]);

  useEffect(() => {
    if (data) {
      const initialFormValues = newData();
      setInitialValues(initialFormValues);
    }
  }, [data, newData]);

  return (
    <Page loading={loading} error={error} fullWidth>
      <Paper sx={{ borderRadius: 4, mt: 4 }}>
        <Grid sx={{ padding: '16px 16px 0 16px' }}>
          <Grid container>
            <Grid item xs={12} className={classes.searchBox}>
              <TextField
                helperText={searchError}
                className={classes.searchBar}
                id="outlined-basic"
                label="Search"
                onChange={handleSearch}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search color="disabled" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Box>
              <aside>
                <CustomDialog
                  isDialogOpen={isDialogOpen}
                  handleCloseDialog={handleCloseDialog}
                  title="Update ingredient"
                >
                  <IngredientForm
                    formAction={formAction}
                    ingredientData={ingredientData}
                    onSubmit={handleOnSubmit}
                    onCancel={handleCloseDialog}
                  />
                </CustomDialog>
              </aside>
            </Box>
          </Grid>

          {data && data.length > 0 && filters && (
            <Grid container sx={{ flexWrap: 'nowrap', overflowY: 'auto' }}>
              <Grid item className={classes.box}>
                <Autocomplete
                  fullWidth
                  multiple
                  id="availability_type"
                  options={availabilityTypes}
                  label="Status"
                  defaultValue={
                    sessionStorageFilters && sessionStorageFilters[0].available.length > 0
                      ? sessionStorageFilters[0].available
                      : []
                  }
                  value={
                    sessionStorageFilters && sessionStorageFilters[0].available.length > 0
                      ? sessionStorageFilters[0].available
                      : []
                  }
                  onChange={(_e, values) => handleFilterChange(_e, values, 'available')}
                  disableCloseOnSelect
                  className={classes.dropdownWidths}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Status"
                      className={classes.dropdownWidths}
                    />
                  )}
                />
              </Grid>
              <Grid item className={classes.box}>
                <Autocomplete
                  fullWidth
                  multiple
                  id="ingredient_type"
                  options={ingredientTypes}
                  defaultValue={
                    sessionStorageFilters && sessionStorageFilters[0].types.length > 0
                      ? sessionStorageFilters[0].types
                      : []
                  }
                  value={
                    sessionStorageFilters && sessionStorageFilters[0].types.length > 0
                      ? sessionStorageFilters[0].types
                      : []
                  }
                  onChange={(_e, values) => handleFilterChange(_e, values, 'types')}
                  disableCloseOnSelect
                  className={classes.dropdownWidths}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Type"
                      className={classes.dropdownWidths}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}

          <Typography paragraph className={classes.paragraph}>
            Ingredients appear as options on products (e.g. mixers for spirits, flavours for ice
            cream)
          </Typography>
        </Grid>

        {filteredItems && filteredItems.length === 0 && (
          <Box style={{ padding: 14 }}>
            <Typography align="center" variant="h2" color="textSecondary">
              No ingredients found
            </Typography>
          </Box>
        )}
        {data && (
          <>
            {filteredItems && filteredItems.length > 0 && (
              <Card>
                <Formik initialValues={[...newData()]}>
                  {({ setFieldValue, values, dirty, resetForm, errors, isValid }) => (
                    <>
                      <UniversalSave
                        isValid={isValid}
                        errors={errors}
                        dirty={dirty}
                        onSave={() => handleAvailableSwitch(values)}
                        onDiscard={resetForm}
                      />
                      <Form>
                        <FormObserver setFieldValue={setFieldValue} setValueData={setValueData} />
                        <OrderableTable
                          tableData={[...newData()]}
                          titles={['INTERNAL NAME', 'EXTERNAL NAME', 'AVAILABLE', 'TYPE', '']}
                          keys={['ingredientId']}
                          excludeFields={['ingredientId', 'isAlcoholic', 'readonly']}
                          disableColumnTitles={['']}
                          values={values}
                          valueFormatter={valueFormatter}
                        />
                      </Form>
                    </>
                  )}
                </Formik>
              </Card>
            )}
          </>
        )}
      </Paper>
    </Page>
  );
};

export default withVenue(Ingredients, null, clearIngredients);
