import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import { fetchDebits as fetchDebitsApi, createDebit as createDebitApi } from '../../api/debits';

export const fetchDebits = createAsyncAction('fetch-debits', fetchDebitsApi);
export const createDebit = createAsyncAction('create-debits', createDebitApi);

const debitsSlice = createSlice({
  name: 'debits',
  initialState,
  reducers: {
    clearDebits(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchDebits),
    ...createAsyncReducerHandlers(createDebit),
  },
});

export const { clearDebits } = debitsSlice.actions;

export const { reducer: debits } = debitsSlice;
