import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import { Card, Link } from '@mui/material';
import { pick } from 'lodash';
import useEndpoint from '../../hooks/useEndpoint';
import OrderableTable from '../OrderableTable';
import { getPlatformInvoices } from '../../store/platformInvoices/selectors';
import { clearPlatformInvoices, fetchPlatformInvoices } from '../../store/platformInvoices';
import withVenue from '../../hoc/withVenue';

const valueFormatter = ({ value, valueName }) => {
  switch (valueName) {
    case 'createdAt':
      return moment(value).format('DD/MM/YYYY');
    case 'invoiceUrl':
      return (
        <Link
          style={{ textDecoration: 'underline' }}
          target="_blank"
          rel="noopener noreferrer"
          href={value}
          download
        >
          Download
        </Link>
      );

    case 'invoiceId':
      return <span>{value}</span>;
    default:
      return value;
  }
};
const PlatformInvoiceHistory = () => {
  const { data: invoices } = useEndpoint(getPlatformInvoices, fetchPlatformInvoices());

  const invoiceDataPicked = useMemo(() => {
    const pickedData = [];
    if (invoices) {
      invoices.forEach((item) => {
        pickedData.push(
          pick(item, [
            'createdAt',
            'invoicePeriod',
            'invoiceId',
            'platformFeeExVat',
            'platformFeeVat',
            'invoiceUrl',
          ]),
        );
      });
    }
    return pickedData;
  }, [invoices]);

  return (
    <Card>
      {invoices && (
        <OrderableTable
          tableData={invoiceDataPicked}
          titles={['INVOICE DATE', 'INVOICE PERIOD', 'INVOICE NUMBER', 'PLATFORM FEE', 'VAT', '']}
          disableColumnTitles={['INVOICE NUMBER', '']}
          valueFormatter={valueFormatter}
        />
      )}
    </Card>
  );
};

export default withVenue(PlatformInvoiceHistory, null, clearPlatformInvoices);
