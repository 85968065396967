import { useCallback } from 'react';
import { usePostHog } from 'posthog-js/react';
import cognitoConfig from '../api/helpers';

const useEventTracker = () => {
  const posthog = usePostHog();
  const { clientId } = cognitoConfig;

  const trackEvent = useCallback(
    (emailIdentity, groupKey, venueId, groupValues) => {
      if (!posthog) return;

      posthog.identify(emailIdentity, {
        email: emailIdentity,
        cognitoId: clientId,
      });

      posthog.group(groupKey, venueId, { venueDetails: groupValues });
    },
    [posthog, clientId],
  );

  return trackEvent;
};

export default useEventTracker;
