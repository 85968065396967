import {
  Box,
  CardContent,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { MuiColorInput, matchIsValidColor } from 'mui-color-input';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';

import BackArrow from '../../components/BackArrow';
import UniversalSave from '../../components/UniversalSave';
import { fetchBrand, updateBrand } from '../../store/brands/brand';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import PhotoUploader from '../../components/PhotoUploader';
import CarouselUploader from './CarouselUploader';

const hexRegex = /^#?[A-Fa-f0-9]{6}$/;

const BrandSettingsSchema = Yup.object().shape({
  primaryFontID: Yup.string()
    .notOneOf(['0'], 'Please select a primary font')
    .required('Please select a primary font'),
  primaryColorHex: Yup.string()
    .matches(hexRegex, 'Please enter a valid hexadecimal color')
    .required('Please select a primary color'),
  onPrimaryColorHex: Yup.string()
    .matches(hexRegex, 'Please enter a valid hexadecimal color')
    .required('Please select a primary color'),
  tileColorHex: Yup.string()
    .matches(hexRegex, 'Please enter a valid hexadecimal color')
    .required('Please select a primary color'),
  onTileColorHex: Yup.string()
    .matches(hexRegex, 'Please enter a valid hexadecimal color')
    .required('Please select a primary color'),
  theme: Yup.string().oneOf(['light', 'dark']).required('Please select a theme'),
});

const initialValues = {
  primaryFontID: '0',
  primaryColorHex: '000000',
  onPrimaryColorHex: 'FFFFFF',
  tileColorHex: '000000',
  onTileColorHex: 'FFFFFF',
  theme: 'light',
  tileColorControl: 'primary',
};

const useStyles = makeStyles(() => ({
  '@global': {
    '.MuiColorInput-Button': {
      minHeight: 24,
    },
  },
  formSubtitle: {
    color: '#5A7296',
  },
}));

const BrandSettings = () => {
  const { brandId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const { data: brand, loading: brandLoading } = useSelector((state) => state.brand);

  const [imageExists, setImageExists] = useState(false);
  const heroImgUrl = `${process.env.REACT_APP_ASSETS_URI}/venues/${
    brand?.brandId
  }/hero.png?ck=${Math.round(Math.random() * 100000)}`;

  const kioskBrandSettings = (brand && brand.kioskBrandSettings) || initialValues;

  useEffect(() => {
    dispatch(fetchBrand(brandId));
  }, [dispatch, brandId]);

  const handleSubmit = async (values) => {
    try {
      await dispatch(updateBrand(brandId, values));
      dispatch(fetchBrand(brandId));
      showSuccessNotification(`${brand.name} kiosk settings updated successfully`);
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const doesImageExist = (url) =>
    new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });

  useEffect(() => {
    const checkImage = async () => {
      if (!heroImgUrl) {
        setImageExists(false);
        return;
      }
      const exists = await doesImageExist(heroImgUrl);
      setImageExists(exists);
    };
    checkImage();
  }, [heroImgUrl]);

  if (brandLoading || !kioskBrandSettings || !brand?.availableFonts) {
    return (
      <Box sx={{ my: 4, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress size={32} />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }} className={classes.root}>
      <Box sx={{ width: { xs: 1, md: 1 / 2 } }}>
        <BackArrow to="/brand-menu-management/menus" text="Brand Menus" />
        <Paper sx={{ p: 1, mt: 1 }}>
          <Typography variant="h6" sx={{ padding: 2, pb: 0, fontWeight: 'bold' }}>
            Kiosk Brand Settings
          </Typography>
          <Formik
            validationSchema={BrandSettingsSchema}
            initialValues={kioskBrandSettings}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, dirty, errors, resetForm, isValid }) => {
              const showCustomTileColors =
                values.tileColorControl === 'custom' ||
                values.tileColorHex !== values.primaryColorHex ||
                values.onTileColorHex !== values.onPrimaryColorHex;

              return (
                <>
                  <UniversalSave
                    dirty={dirty}
                    errors={errors || !isValid}
                    onSave={() => {
                      handleSubmit(values);
                    }}
                    onDiscard={resetForm}
                  />
                  <Form>
                    <CardContent>
                      <FormControl fullWidth>
                        <FormLabel sx={{ fontWeight: 'bold' }}>Brand font</FormLabel>
                        <Field
                          component={TextField}
                          select
                          name="primaryFontID"
                          value={values.primaryFontID}
                          variant="outlined"
                          size="small"
                          sx={{ width: { xs: 1, md: 1 / 2 } }}
                          onChange={({ target }) => setFieldValue('primaryFontID', target.value)}
                          helperText={errors.primaryFontID}
                          error={!!errors.primaryFontID}
                          required
                        >
                          <MenuItem value="0" disabled>
                            Select a font
                          </MenuItem>
                          {brand?.availableFonts.map((font) => (
                            <MenuItem key={font.value} value={font.value}>
                              {font.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>

                      <FormControl fullWidth sx={{ mt: 2 }}>
                        <FormLabel sx={{ fontWeight: 'bold' }}>Hero image</FormLabel>
                        <FormLabel className={classes.formSubtitle} sx={{ mb: 1 }}>
                          Hero image uploaded only takes effect on Deliveroo
                        </FormLabel>
                        <PhotoUploader
                          imgUploadUrl={brand.heroImageUploadUrl}
                          imgUrl={heroImgUrl}
                          hasImage={imageExists}
                          brandId={brandId}
                          isBrandLogo
                          isBrandHero
                          small
                          enforceImageRequirements
                          aspectRatio={16 / 9}
                        />
                      </FormControl>

                      <FormControl fullWidth sx={{ mt: 2 }}>
                        <FormLabel sx={{ fontWeight: 'bold' }}>Logo</FormLabel>
                        <FormLabel className={classes.formSubtitle} sx={{ mb: 1 }}>
                          Use the brand logo thats closest to the shape of a square
                        </FormLabel>
                        <PhotoUploader
                          imgUploadUrl={brand.brandLogoUploadUrl}
                          imgUrl={kioskBrandSettings?.logoURL}
                          hasImage={!!kioskBrandSettings?.logoURL}
                          brandId={brandId}
                          isBrandLogo
                          small
                          enforceImageRequirements
                        />
                      </FormControl>
                      <Field name="theme">
                        {({ field }) => (
                          <RadioGroup {...field} aria-label="theme" sx={{ mt: 2 }}>
                            <FormLabel sx={{ fontWeight: 'bold' }}>Theme</FormLabel>
                            <FormControlLabel value="light" control={<Radio />} label="Light" />
                            <FormControlLabel value="dark" control={<Radio />} label="Dark" />
                          </RadioGroup>
                        )}
                      </Field>
                      <FormControl fullWidth sx={{ mt: 2 }}>
                        <FormLabel sx={{ fontWeight: 'bold' }}>Primary colour</FormLabel>
                        <FormLabel className={classes.formSubtitle}>
                          Buttons, confirmation and significant elements
                        </FormLabel>
                        <MuiColorInput
                          value={values.primaryColorHex}
                          onChange={(color) => {
                            setFieldValue('primaryColorHex', color);
                            if (values.tileColorControl === 'primary') {
                              setFieldValue('tileColorHex', color);
                            }
                          }}
                          error={!matchIsValidColor(values.primaryColorHex)}
                          format="hex"
                          sx={{ width: { xs: 1, md: 1 / 2 } }}
                          size="small"
                          isAlphaHidden
                        />
                      </FormControl>
                      <FormControl fullWidth sx={{ mt: 2 }}>
                        <FormLabel sx={{ fontWeight: 'bold' }}>On-Primary colour</FormLabel>
                        <FormLabel className={classes.formSubtitle}>
                          The colour used for text and icons displayed on top of the primary colour
                        </FormLabel>
                        <MuiColorInput
                          value={values.onPrimaryColorHex}
                          onChange={(color) => {
                            setFieldValue('onPrimaryColorHex', color);
                            if (values.tileColorControl === 'primary') {
                              setFieldValue('onTileColorHex', color);
                            }
                          }}
                          error={!matchIsValidColor(values.onPrimaryColorHex)}
                          format="hex"
                          sx={{ width: { xs: 1, md: 1 / 2 } }}
                          size="small"
                          isAlphaHidden
                        />
                      </FormControl>
                      <Field name="tileColorControl">
                        {({ field, form }) => (
                          <RadioGroup
                            {...field}
                            sx={{ mt: 2 }}
                            onChange={(event) => {
                              form.setFieldValue(field.name, event.target.value);
                              if (event.target.value === 'primary') {
                                form.setFieldValue('tileColorHex', form.values.primaryColorHex);
                                form.setFieldValue('onTileColorHex', form.values.onPrimaryColorHex);
                              }
                            }}
                            value={showCustomTileColors ? 'custom' : 'primary'}
                          >
                            <FormLabel sx={{ fontWeight: 'bold' }}>
                              Tile colour (homepage categories)
                            </FormLabel>
                            <FormControlLabel
                              value="primary"
                              control={<Radio />}
                              label="Same as primary"
                            />
                            <FormControlLabel
                              value="custom"
                              control={<Radio />}
                              label="Custom colour"
                            />
                          </RadioGroup>
                        )}
                      </Field>
                      {showCustomTileColors && (
                        <>
                          <FormControl fullWidth sx={{ mt: 2 }}>
                            <FormLabel sx={{ fontWeight: 'bold' }}>Tile colour</FormLabel>
                            <MuiColorInput
                              value={values.tileColorHex}
                              onChange={(color) => {
                                setFieldValue('tileColorHex', color);
                              }}
                              error={!matchIsValidColor(values.tileColorHex)}
                              format="hex"
                              sx={{ width: { xs: 1, md: 1 / 2 } }}
                              size="small"
                              isAlphaHidden
                            />
                          </FormControl>
                          <FormControl fullWidth sx={{ mt: 2 }}>
                            <FormLabel sx={{ fontWeight: 'bold' }}>On-Tile colour</FormLabel>
                            <FormLabel className={classes.formSubtitle}>
                              The colour used for text on tiles
                            </FormLabel>
                            <MuiColorInput
                              value={values.onTileColorHex}
                              onChange={(color) => {
                                setFieldValue('onTileColorHex', color);
                              }}
                              error={!matchIsValidColor(values.onTileColorHex)}
                              format="hex"
                              sx={{ width: { xs: 1, md: 1 / 2 } }}
                              size="small"
                              isAlphaHidden
                            />
                          </FormControl>
                        </>
                      )}
                      <FormControl fullWidth sx={{ mt: 2 }}>
                        <FormLabel sx={{ fontWeight: 'bold', mb: 1 }}>
                          Splash page carousel images
                        </FormLabel>
                        <CarouselUploader
                          brandId={brandId}
                          brandCarouselImageUrls={kioskBrandSettings?.carouselImageURLs || ['']}
                          brandCarouselImageUploadUrls={brand.brandCarouselImageUploadUrls}
                        />
                      </FormControl>
                    </CardContent>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Paper>
      </Box>
    </Box>
  );
};

export default BrandSettings;
