export const customPalette = {
  brandGreen: '#69C0AC',
  successLightGreen: '#EEFFF0',
  warningYellow: '#FFF7E3',
  warmYellow: '#FFB800',
  greyDark: '#647F99',
  greyDarker: '#334E68',
  greyLight: '#D9E2EC',
  greyLightest: '#F0F4F8',
  grey: '#BCCCDC',
  white: '#FFFFFF',
  trueBlue: '#1870ED',
  cheeryBlue: '#12B4FF',
  chargedUpBlue: '#69C0AC',
  chargedUpBluehover: '#7EBBAC',
  destructiveRed: '#D2222D',
  subscriptionPurple: '#5F5FD7',
  subscriptionPink: '#F5A8D1',
  midnight: '#333333',
  black: '#000000',
  // figma numbered colors
  n1: '#F2F2F7',
  n2: '#E0E0EB',
  n3: '#D0CFDF',
  n4: '#96A5C2',
  n5: '#5A7296',
  n6: '#343860',
  n7: '#181C43',
};

export const sessionsScorePalette = {
  nonCompliantDarkGrey: '#525252',
  nonCompliantLightGrey: '#F2F2F7',
  compliantGreen: '#16a34a',
  nonCompliantRed: '#dc2626',
  reallyGreen: '#094A25',
  green: '#0F6030',
  reallyRed: '#b41d20',
  red: '#EB442C',
  yellow: '#F8B324',
};
