import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import Page from '../../components/Page';
import withVenue from '../../hoc/withVenue';
import { getVenue, isDeliveryOnly } from '../../store/venues/selectors';
import { getVenueMenusState } from '../../store/venueMenus/selectors';
import shouldLoad from '../../shared/utils/shouldLoad';
import { fetchVenueMenus, clearVenueMenus } from '../../store/venueMenus';
import menuTypes from '../../shared/constants/menuTypes';
import useRoles from '../../hooks/useRoles';
import MenuTile from './MenuTile';
import EditMenu from './MenuTile/ActionModals/EditMenu';

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  pageTitle: {
    ...theme.customFonts.largeBold,
  },
  addBtn: {
    ...theme.customFonts.medium,
    borderRadius: 12,
    borderColor: '#BFCBDA',
    background: theme.customPalette.white,
  },
  menuSectionTitle: {
    ...theme.customFonts.mediumBold,
    marginBottom: theme.spacing(1),
  },
  menuTileGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(1.5),
    },
  },
}));

const Menus = () => {
  const [actionModal, setActionModal] = useState(null);
  const { isAdmin } = useRoles();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { name: venueName } = useSelector(getVenue);
  const typeDeliveryOnly = useSelector(isDeliveryOnly);

  const venueMenusState = useSelector(getVenueMenusState);
  const permissionReadOnly = !isAdmin() && typeDeliveryOnly;

  const { loading: menusLoading, data: menus, error: menusError } = venueMenusState;

  useEffect(() => {
    if (shouldLoad(venueMenusState)) {
      dispatch(fetchVenueMenus());
    }
  }, [dispatch, venueMenusState]);

  const ownMenus = menus
    ?.filter(
      ({ type }) =>
        type === menuTypes.EPOS.value ||
        type === menuTypes.WEB.value ||
        type === menuTypes.DELIVERY_INHOUSE.value,
    )
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const sessionsMenus = menus
    ?.filter(({ type }) => type === menuTypes.DELIVERY.value)
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const showVenueName = ownMenus && ownMenus.length > 0 && sessionsMenus.length > 0;
  const showSessionsHeader = sessionsMenus && sessionsMenus.length > 0 && ownMenus.length > 0;

  return (
    <>
      <Page loading={menusLoading} error={menusError}>
        <PageHeader className={classes.pageHeader}>
          <Typography variant="h1" className={classes.pageTitle}>
            Menus
          </Typography>
          <Button
            variant="outlined"
            className={classes.addBtn}
            onClick={() => setActionModal('ADD_MENU')}
            disabled={permissionReadOnly}
          >
            Add
          </Button>
        </PageHeader>
        {ownMenus && ownMenus.length > 0 && (
          <Box sx={{ marginBottom: 5 }}>
            {showVenueName && (
              <Typography variant="h2" className={classes.menuSectionTitle}>
                {venueName}
              </Typography>
            )}
            <Box className={classes.menuTileGrid}>
              {ownMenus &&
                ownMenus.map((ownedMenu) => <MenuTile menu={ownedMenu} key={ownedMenu.menuName} />)}
            </Box>
          </Box>
        )}
        {sessionsMenus && sessionsMenus.length > 0 && (
          <Box>
            {showSessionsHeader && (
              <Typography variant="h2" className={classes.menuSectionTitle}>
                Sessions
              </Typography>
            )}
            <Box className={classes.menuTileGrid}>
              {sessionsMenus.map((sessionsMenu) => (
                <MenuTile
                  menu={sessionsMenu}
                  key={sessionsMenu.menuName}
                  isAdmin={isAdmin()}
                  sessionsOwnedMenu
                />
              ))}
            </Box>
          </Box>
        )}
        {!menusLoading && menus && (
          <EditMenu
            mode="CREATE"
            open={actionModal === 'ADD_MENU'}
            onClose={() => setActionModal(null)}
          />
        )}
      </Page>
    </>
  );
};

export default withVenue(Menus, '/menus', clearVenueMenus);
