import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Card, Chip, IconButton, Typography, Paper, Grid } from '@mui/material';

import { pick } from 'lodash';
import { MdDelete } from 'react-icons/md';
import { makeStyles } from '@mui/styles';
import { deleteCategory, fetchCategories, clearCategories } from '../../store/categories';
import { getCategoriesState } from '../../store/categories/selectors';
import { isDeliveryOnly } from '../../store/venues/selectors';

import Page from '../../components/Page';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import withVenue from '../../hoc/withVenue';
import shouldLoad from '../../shared/utils/shouldLoad';
import OrderableTable from '../../components/OrderableTable';
import useRoles from '../../hooks/useRoles';

const useStyles = makeStyles(() => ({
  paragraph: {
    marginBottom: 0,
  },
}));

const Categories = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const categoriesState = useSelector(getCategoriesState);
  const { loading, data, error } = categoriesState;
  const [valueData, setValueData] = useState(data);
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { isAdmin } = useRoles();
  const typeDeliveryOnly = useSelector(isDeliveryOnly);

  const permissionReadOnly = !isAdmin() && typeDeliveryOnly;

  const handleDelete = useCallback(
    async (categoryId) => {
      try {
        await dispatch(deleteCategory(categoryId));
        showSuccessNotification('Category has been deleted successfully');
      } catch (err) {
        await dispatch(fetchCategories());
        showErrorNotification(getErrorMessage(err));
      }
    },
    [dispatch, showErrorNotification, showSuccessNotification],
  );

  const newData = useCallback(() => {
    const pickedData = [];
    if (data) {
      data.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item = {
          ...item,
          delete: 'delete',
        };
        pickedData.push(pick(item, ['categoryName', 'type', 'delete', 'categoryId', 'readonly']));
      });
    }
    return pickedData;
  }, [data]);

  const valueFormatter = useCallback(
    ({ value, valueName, row }) => {
      switch (valueName) {
        case 'delete':
          return (
            <IconButton
              disabled={row.readonly || permissionReadOnly}
              edge="end"
              size="small"
              onClick={() => handleDelete(row.categoryId)}
            >
              <MdDelete />
            </IconButton>
          );
        case 'modifierItems':
          return row.modifierItems.length;
        case 'categoryName':
          return row.readonly ? (
            <p>
              {row.categoryName} <Chip label="Read-only" />
            </p>
          ) : (
            row.categoryName
          );
        default:
          return value;
      }
    },
    [handleDelete, permissionReadOnly],
  );

  useEffect(() => {
    if (shouldLoad(categoriesState)) dispatch(fetchCategories());
    setValueData(data);
  }, [categoriesState, data, dispatch]);

  return (
    <Page loading={loading} error={error} fullWidth>
      <Paper sx={{ borderRadius: 4, mt: 4 }}>
        <Grid sx={{ padding: '16px 16px 0 16px' }}>
          <Typography paragraph className={classes.paragraph}>
            Categories are not visible to your customers, but are used for reporting. Use categories
            to describe the product type (e.g. Wine, Whiskey, Pizzas). Use{' '}
            <Button color="primary" size="small" component={Link} to="/menus">
              Menu Sections
            </Button>{' '}
            to group things together on your menu.
          </Typography>
        </Grid>
        {data && (
          <>
            {valueData && data && (
              <Card>
                <>
                  <OrderableTable
                    tableData={[...newData()]}
                    titles={['NAME', 'TYPE', '']}
                    keys={['categoryId']}
                    excludeFields={['categoryId']}
                    disableColumnTitles={['']}
                    valueFormatter={valueFormatter}
                  />
                </>
              </Card>
            )}
          </>
        )}
      </Paper>
    </Page>
  );
};

export default withVenue(Categories, null, clearCategories);
