import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';

import { fetchPlatformInvoices as fetchPlatformInvoicesApi } from '../../api/platformInvoices';

export const fetchPlatformInvoices = createAsyncAction(
  'fetch-platforminvoices',
  fetchPlatformInvoicesApi,
);

const platformInvoicesSlice = createSlice({
  name: 'platformInvoices',
  initialState,
  reducers: {
    clearPlatformInvoices(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchPlatformInvoices),
  },
});

export const { clearPlatformInvoices } = platformInvoicesSlice.actions;

export const { reducer: platformInvoices } = platformInvoicesSlice;
