import React from 'react';
import { CardContent, FormControl, FormControlLabel, Radio, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Field } from 'formik';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import MomentUtils from '@date-io/moment';
import moment from 'moment';
import WeekTimeRangeSelectorInline from '../WeekTimeRangeSelector/WeekTimeRangeSelectorInline';
import withVenue from '../../hoc/withVenue';

const useStyles = makeStyles((theme) => ({
  name: {
    color: theme.palette.primary.main,
  },
}));

const AddSchedule = ({ venue, values, setFieldValue }) => {
  const classes = useStyles();
  const { timezone } = venue || {};

  const handleStartChange = ({ currentTarget }) => {
    setFieldValue('startgroup', currentTarget.value);
    if (currentTarget.value === 'now') {
      setFieldValue('startsAt', 'now');
    }
    if (currentTarget.value === 'startDate') {
      setFieldValue('startsAt', null);
    }
  };
  const handleEndChange = ({ currentTarget }) => {
    setFieldValue('endgroup', currentTarget.value);
    if (currentTarget.value === 'never') {
      setFieldValue('endsAt', 'never');
    }
    if (currentTarget.value === 'endDate') {
      setFieldValue('endsAt', null);
    }
  };
  const handleScheduleChange = ({ currentTarget }) => {
    if (currentTarget.value === 'schedulePicker') {
      setFieldValue('schedulegroup', 'schedulePicker');
    } else if (currentTarget.value === 'scheduleNull') {
      setFieldValue('schedulegroup', 'scheduleNull');
    }
  };

  return (
    <>
      <div className={classes.cardContainer}>
        <CardContent>
          <Typography variant="h2">Starts</Typography>
          <FormControl component="fieldset">
            <FormControlLabel
              checked={values.startgroup === 'now'}
              onChange={(event) => {
                handleStartChange(event);
              }}
              name="startgroup"
              value="now"
              control={<Radio color="primary" />}
              label="Now"
            />
            <FormControlLabel
              checked={values.startgroup === 'startDate'}
              onChange={handleStartChange}
              name="startgroup"
              value="startDate"
              control={<Radio color="primary" />}
              label="Choose a start date"
            />
          </FormControl>
          {values.startgroup === 'startDate' && (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Field
                component={DateTimePicker}
                autoOk
                fullWidth
                inputVariant="outlined"
                timezone="system"
                size="small"
                label="Start date"
                format="DD/MM/YYYY HH:mm"
                value={moment(values.startsAt)}
                onChange={(date) => {
                  setFieldValue('startsAt', moment(date).format());
                }}
                ampm={false}
              />
            </LocalizationProvider>
          )}
        </CardContent>
        <CardContent>
          <Typography variant="h2">Ends</Typography>
          <FormControl component="fieldset">
            <FormControlLabel
              checked={values.endgroup === 'never'}
              onChange={(event) => {
                handleEndChange(event);
              }}
              name="endgroup"
              value="never"
              control={<Radio color="primary" />}
              label="Never (until manually deactivated)"
            />
            <FormControlLabel
              checked={values.endgroup === 'endDate'}
              onChange={handleEndChange}
              name="endgroup"
              value="endDate"
              control={<Radio color="primary" />}
              label="End date"
            />
          </FormControl>
          {values.endgroup === 'endDate' && (
            <>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Field
                  component={DateTimePicker}
                  autoOk
                  fullWidth
                  name="endsAt"
                  timezone="system"
                  inputVariant="outlined"
                  size="small"
                  label="End date"
                  format="DD/MM/YYYY HH:mm"
                  value={moment(values.endsAt)}
                  onChange={async (date) => {
                    await setFieldValue('endsAt', moment(date).format());
                  }}
                  ampm={false}
                />
              </LocalizationProvider>
            </>
          )}
        </CardContent>
        <CardContent>
          <Typography variant="h2">Choose days and times</Typography>
          <Typography variant="body2" className={classes.subtitle}>
            e.g. from 12:00 to 16:00 on Wed and Fri
          </Typography>
          <FormControl component="fieldset">
            <FormControlLabel
              checked={Boolean(values.schedulegroup === 'scheduleNull')}
              onChange={(event) => {
                handleScheduleChange(event);
              }}
              name="schedulegroup"
              value="scheduleNull"
              control={<Radio color="primary" />}
              label="Always active"
            />
            <FormControlLabel
              checked={Boolean(values.schedulegroup !== 'scheduleNull')}
              onChange={handleScheduleChange}
              name="schedulegroup"
              value="schedulePicker"
              control={<Radio color="primary" />}
              label="Activate discount on specific days and times"
            />
          </FormControl>
          {Boolean(values.schedulegroup !== 'scheduleNull') && (
            <Field
              name="openings"
              ariaLabelledBy="validTimes"
              label="Valid Times"
              validTimes={values.openings || []}
              timezone={timezone}
              component={WeekTimeRangeSelectorInline}
              variant="outlined"
              margin="normal"
              required
              setFieldValue={setFieldValue}
            />
          )}
        </CardContent>
      </div>
    </>
  );
};

export default withVenue(AddSchedule);
