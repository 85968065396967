import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import { makeStyles } from '@mui/styles';
import { Box, Button, Chip, Paper, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import BackArrow from '../../components/BackArrow';
import shouldLoad from '../../shared/utils/shouldLoad';
import { fetchPayout, getPayoutState } from '../../store/payout';
import Page from '../../components/Page';
import PayoutType from './PayoutType';
import PayoutBreakdown from './PayoutBreakdown';
import PayoutDetails from './PayoutDetails';
import payoutBreakdownValues from '../../shared/constants/payoutBreakdowns';

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    ...theme.customFonts.mediumBold,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  payoutTotal: {
    ...theme.customFonts.xLarge,
  },
  payoutId: {
    ...theme.customFonts.xSmall,
    marginTop: theme.spacing(1),
  },
  statusPaid: {
    backgroundColor: '#87EB80',
    color: theme.customPalette.n7,
    marginLeft: theme.spacing(1),
  },
  statusNeutral: {
    backgroundColor: theme.customPalette.n2,
    color: theme.customPalette.n7,
    marginLeft: theme.spacing(1),
  },
  statusTransit: {
    backgroundColor: '#DFDFFF',
    color: theme.customPalette.n7,
    marginLeft: theme.spacing(1),
  },
  statusFailed: {
    backgroundColor: '#FF4200',
    color: '#FFF',
    marginLeft: theme.spacing(1),
  },
}));

const Payout = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { payoutId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const payoutDate = queryParams.get('date');

  const { data: payout, loading, error } = useSelector(getPayoutState);

  const { pdfLink, total, type, status, bankDetails, breakdown, sessionsFees, adjustments } =
    payout || {};

  useEffect(() => {
    if (shouldLoad(getPayoutState)) {
      dispatch(fetchPayout({ payoutId }));
    }
  }, [dispatch, payoutId]);

  const PayoutStatus = {
    COMPLETED: <Chip label="Paid" color="success" className={classes.statusPaid} size="small" />,
    IN_PROGRESS: <Chip label="In progress" className={classes.statusNeutral} size="small" />,
    IN_TRANSIT: <Chip label="In transit" className={classes.statusTransit} size="small" />,
    NOT_STARTED: <Chip label="Not started" className={classes.statusNeutral} size="small" />,
    FAILED: <Chip label="Failed" className={classes.statusFailed} size="small" />,
    RETURNED: <Chip label="Returned" className={classes.statusFailed} size="small" />,
  };

  return (
    <>
      <PageHeader>
        <BackArrow to="/finance/payouts" text="Payouts" />
        <Box className={classes.headerContainer}>
          <Typography variant="h1">Payout: {payoutDate}</Typography>
          {pdfLink && (
            <Button
              variant="outlined"
              onClick={() => pdfLink && window.open(pdfLink, '_blank', 'noopener,noreferrer')}
              sx={{ backgroundColor: '#FFF' }}
            >
              Download pdf
            </Button>
          )}
        </Box>
      </PageHeader>
      <Page loading={loading} error={error}>
        <Paper sx={{ mt: 3, p: 2 }}>
          <Box>
            <Typography variant="h1" className={classes.payoutTotal}>
              {total}
            </Typography>
            <Box className={classes.statusContainer}>
              <PayoutType type={type} />
              {PayoutStatus[status]}
            </Box>
            <Typography variant="subtitle1" className={classes.payoutId}>
              {payoutId}
            </Typography>
          </Box>
          {breakdown && (
            <PayoutBreakdown
              breakdown={breakdown}
              bankDetails={bankDetails}
              type={type}
              payoutBreakdownValues={payoutBreakdownValues}
              total={total}
            />
          )}
        </Paper>
        <Paper sx={{ mt: 3, p: 2 }}>
          <Typography variant="h2" className={classes.sectionTitle}>
            Details
          </Typography>
          {sessionsFees && <PayoutDetails tableType="FEES" tableData={sessionsFees} />}
          {adjustments && <PayoutDetails tableType="ADJUSTMENTS" tableData={adjustments} />}
        </Paper>
      </Page>
    </>
  );
};

export default Payout;
