import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import { useDispatch } from 'react-redux';
import { Close, WarningAmberOutlined } from '@mui/icons-material';
import { ReactComponent as DiamondExclaimation } from '../../../../assets/images/diamond_exclaimation.svg';
import { ReactComponent as DiamondExclaimationGrey } from '../../../../assets/images/diamond_exclaimation_grey.svg';

import { deleteVenueMenu, fetchVenueMenus } from '../../../../store/venueMenus';
import { useNotifications } from '../../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../../shared/utils/errors';

const useStyles = makeStyles((theme) => ({
  dialog: {
    borderRadius: 8,
  },
  dialogTitleWrapper: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-start',
  },
  dialogTitle: {
    ...theme.customFonts.largeBold,
    padding: theme.spacing(2),
    color: '#181C43',
  },
  dialogClostBtn: {
    padding: theme.spacing(2),
  },
  bodyText: {
    ...theme.customFonts.medium,
    color: '#181C43',
  },
  activeMenuWarning: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#FFE87A',
    color: theme.customPalette.black,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderRadius: 8,
  },
  btnContainer: {
    borderTop: '1px solid #E0E0EB',
    padding: theme.spacing(2),
    '& .Mui-disabled	': {
      backgroundColor: theme.customPalette.n1,
      color: '#D0CFDF',
    },
  },
  cancelBtn: {
    ...theme.customFonts.mediumBold,
    borderRadius: 12,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  deleteBtn: {
    ...theme.customFonts.mediumBold,
    backgroundColor: '#CA1D2A',
    borderRadius: 12,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    '& .Mui-disabled': {
      backgroundColor: '#CA1D2A',
    },
  },
}));

const DeleteMenu = ({ open, onClose, displayMenuName, active, menuName }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const handleDeleteMenu = async () => {
    try {
      await dispatch(deleteVenueMenu(menuName));

      await dispatch(fetchVenueMenus());
      showSuccessNotification(`${displayMenuName} deleted successfully`);
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog} maxWidth="xs" fullWidth>
      <Box className={classes.dialogTitleWrapper}>
        <DialogTitle className={classes.dialogTitle}>Duplicate menu</DialogTitle>
        <IconButton onClick={onClose} className={classes.dialogClostBtn}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent sx={{ padding: 2 }}>
        <Typography variant="body1" className={classes.bodyText}>
          {displayMenuName} is going to be deleted!
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          This action is irreversible!
        </Typography>
        {active && (
          <Box className={classes.activeMenuWarning}>
            <WarningAmberOutlined sx={{ mr: 2 }} />
            <Typography variant="body1">
              This menu is currently active. In order to delete this menu, you must deactivate it
              first.
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions className={classes.btnContainer}>
        <Button variant="outlined" onClick={onClose} className={classes.cancelBtn}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          className={classes.deleteBtn}
          startIcon={active ? <DiamondExclaimationGrey /> : <DiamondExclaimation />}
          onClick={() => handleDeleteMenu()}
          disabled={active}
          disableElevation
        >
          Delete menu
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteMenu;
