import { pick } from 'lodash';
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../../components/Page';
import VenueDeletePriorityDialog from '../../../components/VenueDeletePriorityDialog';
import VenueEditPriorityDialog from '../../../components/VenueEditPriorityDialog';
import VenueOpenDialog from '../../../components/VenueOpenDialog';
import VenuePrioritiesForm from '../../../components/VenuePrioritiesForm';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import shouldLoad from '../../../shared/utils/shouldLoad';
import {
  clearVenuePriorities,
  createVenuePriority,
  deleteVenuePriority,
  fetchVenuePriorities,
  updateVenuePriorities,
  updateVenuePriority,
} from '../../../store/venues';
import { getVenuePrioritiesState } from '../../../store/venues/selectors';
import withVenue from '../../../hoc/withVenue';

const Courses = () => {
  const dispatch = useDispatch();
  const venuePrioritiesState = useSelector(getVenuePrioritiesState);
  const {
    loading: prioritiesLoading,
    data: venuePriorities,
    error: prioritiesError,
  } = venuePrioritiesState;
  const { priorities, enabled } = venuePriorities || {};
  const isLastPriority = priorities?.length === 1;
  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const [currentPriority, setCurrentPriority] = useState(null);
  const [venueIsOpenDialogOpen, setVenueIsOpenDialogOpen] = useState(false);
  const [venueEditPriorityDialogOpen, setVenueEditPriorityDialogOpen] = useState(false);
  const [venueDeletePriorityDialogOpen, setVenueDeletePriorityDialogOpen] = useState(false);

  const handleVenueIsOpenDialogClose = () => setVenueIsOpenDialogOpen(false);
  const handleVenueEditPriorityDialogClose = () => setVenueEditPriorityDialogOpen(false);
  const handleVenueDeletePriorityDialogClose = () => setVenueDeletePriorityDialogOpen(false);

  const handleOpenEditDialog = (priority) => {
    setCurrentPriority(priority);
    setVenueEditPriorityDialogOpen(true);
  };

  const handleOpenDeleteDialog = (priority) => {
    setCurrentPriority(priority);
    setVenueDeletePriorityDialogOpen(true);
  };

  const handleOnPrioritiesSubmit = async (values) => {
    try {
      const payload = {
        ...values,
        priorities: values.priorities.map((priority) => pick(priority, ['priorityId', 'name'])),
      };

      await dispatch(updateVenuePriorities(payload));

      showSuccessNotification(`Successfully edited courses.`);

      await dispatch(fetchVenuePriorities());
    } catch (error) {
      showErrorNotification(`An error occurred while editing courses.`);
    }
  };

  const handleVenuePriorityOnSubmit = async (values) => {
    const payload = { name: values.name };

    try {
      if (currentPriority) {
        const { priorityId } = currentPriority;

        await dispatch(updateVenuePriority(priorityId, payload));
        showSuccessNotification(`Successfully renamed ${values.name} course.`);
      } else {
        await dispatch(createVenuePriority(payload));
        showSuccessNotification(`Successfully created ${values.name} course.`);
      }

      await dispatch(fetchVenuePriorities());
    } catch (error) {
      showErrorNotification(`An error occurred while editing ${values.name} course.`);
    } finally {
      setVenueEditPriorityDialogOpen(false);
    }
  };

  const handleVenueDeletePriorityOnSubmit = useCallback(async () => {
    const { name, priorityId } = currentPriority;

    try {
      if (isLastPriority) {
        const disablePrioritiesPayload = {
          enabled: false,
          priorities: [],
        };

        await dispatch(updateVenuePriorities(disablePrioritiesPayload));
      } else {
        await dispatch(deleteVenuePriority(priorityId));
      }

      showSuccessNotification(`Successfully deleted ${name} course.`);

      await dispatch(fetchVenuePriorities());
    } catch (error) {
      showErrorNotification(`An error occurred while deleting ${name} course.`);
    } finally {
      setVenueDeletePriorityDialogOpen(false);
    }
  }, [currentPriority, dispatch, isLastPriority, showErrorNotification, showSuccessNotification]);

  useEffect(() => {
    if (shouldLoad(venuePrioritiesState)) dispatch(fetchVenuePriorities());
  }, [dispatch, venuePrioritiesState]);

  return (
    <Page loading={prioritiesLoading} error={prioritiesError}>
      <VenuePrioritiesForm
        initialValues={{
          enabled,
          priorities,
        }}
        handleOpenEditDialog={handleOpenEditDialog}
        handleOpenDeleteDialog={handleOpenDeleteDialog}
        onSubmit={handleOnPrioritiesSubmit}
      />
      <VenueEditPriorityDialog
        handleCloseDialog={handleVenueEditPriorityDialogClose}
        dialogOpen={venueEditPriorityDialogOpen}
        initialValues={currentPriority}
        onSubmit={handleVenuePriorityOnSubmit}
      />
      <VenueDeletePriorityDialog
        handleCloseDialog={handleVenueDeletePriorityDialogClose}
        dialogOpen={venueDeletePriorityDialogOpen}
        onSubmit={handleVenueDeletePriorityOnSubmit}
        courseName={currentPriority?.name}
      />
      <VenueOpenDialog
        handleCloseDialog={handleVenueIsOpenDialogClose}
        dialogOpen={venueIsOpenDialogOpen}
        message="Sorry, you can't change the course priority while the venue is open"
      />
    </Page>
  );
};

export default withVenue(Courses, null, clearVenuePriorities);
