import React, { useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, Card, CardContent, IconButton, Typography } from '@mui/material';
import { MdDelete } from 'react-icons/md';
import { Field, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';
import { move } from 'ramda';
import { Link } from 'react-router-dom';
import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import BackArrow from '../../components/BackArrow';
import withVenue from '../../hoc/withVenue';
import useEndpoint from '../../hooks/useEndpoint';
import { getUpsellGroupState } from '../../store/upsellGroups/selectors';
import { clearUpsellGroup, fetchUpsellGroup, updateUpsellGroup } from '../../store/upsellGroups';
import { getMenuItemsState } from '../../store/menuItems/selectors';
import { clearMenuItems, fetchMenuItems } from '../../store/menuItems';
import CustomWarningBanner from '../../components/CustomWarningBanner';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import UniversalSave from '../../components/UniversalSave';
import Droppable from '../../components/DragAndDrop/Droppable';
import Draggable from '../../components/DragAndDrop/Draggable';
import PageLoader from '../../components/PageLoader';
import AddGroupItems from '../../components/AddGroupItems';
import isItemUnavailable from '../../shared/utils/isItemUnavailable';
import useItemsOnMenu from '../../hooks/useItemsOnMenu';
import { getVenuesSettingsState } from '../../store/venues/selectors';
import { clearVenueSettings, fetchVenueSettings } from '../../store/venues';

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.customFonts.largeBold,
    marginBottom: '1rem',
  },
  card: {
    borderRadius: '8px',
    width: '100%',
  },
  list: {
    maxWidth: '600px',
  },
  cardContent: {
    width: '100%',
  },
  subtitle: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDark,
    marginBottom: '1rem',
    marginTop: '0.5rem',
  },
  suggestedItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '12px',
  },
  suggestedItemText: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
    padding: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  suggestedItemBackground: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.customPalette.greyLightest,
    flexGrow: 1,
    borderRadius: '4px',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '2.5rem',
  },
  warningBannerMin: {
    marginTop: '28px',
  },
  editIcon: {
    marginRight: '8px',
  },
  deleteIcon: {
    marginLeft: '8px',
  },
  listItem: {
    paddingLeft: 0,
  },
  dragIcon: {
    margin: '6px',
  },
  unavailableIcon: {
    width: '16px',
    height: '16px',
    marginLeft: '16px',
  },
  warningBanner: {
    marginBottom: '16px',
  },
  warningContent: {
    color: theme.customPalette.greyDarker,
    ...theme.customFonts.small,
  },
  brandItemSymbol: {
    width: '24px',
    height: '24px',
    marginLeft: theme.spacing(1),
  },
}));

const CheckoutUpsellGroup = ({ redirect }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [itemsForDeletion, setItemsForDeletion] = useState();
  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { checkIfItemIsOnMenu } = useItemsOnMenu();
  const { data: venueSettings } = useEndpoint(getVenuesSettingsState, fetchVenueSettings()) || {};
  const isItemUpsellEnabled = !!venueSettings?.find(
    (setting) => setting.settingName === 'UPSELLS' && setting.value.checkout,
  );

  const isSessionsBrandsEnabled = !!venueSettings?.find(
    (setting) => setting.settingName === 'SESSIONS_BRAND_ENABLED' && setting.value === true,
  );

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const isItemForDeletion = (item) => itemsForDeletion?.includes(item?.itemId);

  const { data: upsellGroup, loading: upsellsLoading } =
    useEndpoint(getUpsellGroupState, fetchUpsellGroup({ upsellGroupId: '3' })) || {};
  const { data: menuItems } = useEndpoint(getMenuItemsState, fetchMenuItems()) || {};

  const eligibleUpsellItems = isSessionsBrandsEnabled
    ? menuItems
    : menuItems?.filter((item) => !item.brandId);

  const filteredCheckoutItems = upsellGroup?.suggestedItemIds?.map((id) =>
    menuItems?.find((item) => item?.itemId === id),
  );

  const initialValues = useMemo(
    () => ({
      filteredCheckoutItems: upsellGroup?.suggestedItemIds,
    }),
    [upsellGroup],
  );

  const isLessThanThreeWarning = filteredCheckoutItems?.length < 3;
  const isTenWarning = filteredCheckoutItems?.length >= 10;

  const handleDelete = (setFieldValue, index) => {
    setFieldValue(`filteredCheckoutItems.${[index]}`, '');
  };

  const handleOnSubmit = async (values) => {
    try {
      const transformedValues = values.filteredCheckoutItems.filter((value) => value !== '');
      await dispatch(
        updateUpsellGroup({
          upsellGroupId: '3',
          data: { type: 'CHECKOUT_UPSELLS', suggestedItemIds: transformedValues },
        }),
      );
      showSuccessNotification('Suggested item updated successfully');
      await dispatch(fetchUpsellGroup({ upsellGroupId: '3' }));
      setItemsForDeletion(null);
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const onDragEnd = ({ source, destination }) => {
    const swapped = move(source.index, destination.index, upsellGroup?.suggestedItemIds);

    handleOnSubmit({ filteredCheckoutItems: swapped });
  };

  const handleOnSave = async (values) => {
    const ids = values.filter((value) => value.checked === true).map((value) => value.itemId);
    const transformedIds = [];
    // Retains order
    upsellGroup?.suggestedItemIds?.forEach((id) => {
      if (ids.includes(id)) {
        transformedIds.push(id);
        ids.splice(ids.indexOf(id), 1);
      }
    });
    transformedIds.push(...ids);
    try {
      await dispatch(
        updateUpsellGroup({
          upsellGroupId: '3',
          data: { type: 'CHECKOUT_UPSELLS', suggestedItemIds: transformedIds },
        }),
      );
      showSuccessNotification('Suggested items updated successfully');
      handleCloseDialog();
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    menuItems && (
      <Page>
        <PageHeader>
          <BackArrow redirect={redirect} text="Upsells" />
          <Typography className={classes.title}>Checkout upsells</Typography>
        </PageHeader>
        {venueSettings && !isItemUpsellEnabled && (
          <div className={classes.warningBanner}>
            <CustomWarningBanner
              backgroundColor="#FFF7E3"
              iconColor="#FFB800"
              title="Checkout upsells are disabled in your venue settings"
              titleFont="smallBold"
              titleColor="greyDarker"
            >
              <Typography className={classes.warningContent}>
                You can enable checkout upsells in your venue settings{' '}
                <Link to="/settings/venue/upsells">here</Link>.
              </Typography>
            </CustomWarningBanner>
          </div>
        )}
        <Card className={classes.card}>
          <Formik initialValues={initialValues} enableReinitialize>
            {({ setFieldValue, values, isValid, dirty, errors, resetForm }) => (
              <>
                <UniversalSave
                  isValid={isValid}
                  dirty={dirty}
                  errors={errors}
                  onDiscard={() => {
                    resetForm();
                    setItemsForDeletion(null);
                  }}
                  onSave={() => handleOnSubmit(values)}
                />
                <Form>
                  <CardContent className={classes.cardContent}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleOpenDialog}
                      disabled={!!(upsellGroup && upsellGroup.error)}
                    >
                      Add upsell
                    </Button>
                    <Typography className={classes.subtitle}>
                      Add between 3 and 10 upsells
                    </Typography>
                    {isTenWarning && (
                      <CustomWarningBanner
                        iconColor="#647F99"
                        backgroundColor="greyLightest"
                        titleFont="smallBold"
                        titleColor="greyDarker"
                        title="Maximum number of upsells reached"
                        warning="Only the top 10 upsells will appear at checkout"
                        warningFont="small"
                      />
                    )}
                    <Box className={classes.list}>
                      <DragDropContext onDragEnd={onDragEnd}>
                        {filteredCheckoutItems && filteredCheckoutItems.length > 0 && (
                          <Droppable
                            droppableId="attributes"
                            type="droppable_attributes"
                            isDropDisabled={filteredCheckoutItems?.length < 2}
                          >
                            <>
                              {upsellsLoading ? (
                                <PageLoader small />
                              ) : (
                                <>
                                  {filteredCheckoutItems?.map(
                                    (item, index) =>
                                      !isItemForDeletion(item) &&
                                      item && (
                                        <Draggable
                                          className={classes.listItem}
                                          key={item?.itemId}
                                          draggableId={`suggestion-${item?.itemId}`}
                                          index={index}
                                          isDragDisabled={filteredCheckoutItems.length < 2}
                                          dragIconClassName={classes.dragIcon}
                                        >
                                          <Field
                                            name={`filteredCheckoutItems${[index]}`}
                                            key={item?.itemId}
                                          >
                                            {() => (
                                              <Box className={classes.suggestedItem}>
                                                <Box
                                                  className={classes.suggestedItemBackground}
                                                  style={
                                                    index > 10
                                                      ? {
                                                          opacity: 0.5,
                                                        }
                                                      : null
                                                  }
                                                >
                                                  {!isItemUnavailable(item) &&
                                                    checkIfItemIsOnMenu(item.itemId) && (
                                                      <span
                                                        className={classes.suggestedItemText}
                                                        style={{ justifyContent: 'flex-start' }}
                                                      >
                                                        {item.itemName}
                                                        {item.brandName && ` | ${item.brandName}`}
                                                        {item.brandName && (
                                                          <img
                                                            src="/img/sessions_symbol.svg"
                                                            className={classes.brandItemSymbol}
                                                            alt="Sessions Brand Item"
                                                          />
                                                        )}
                                                      </span>
                                                    )}
                                                  {!checkIfItemIsOnMenu(item.itemId) && (
                                                    <div
                                                      className={classes.suggestedItemText}
                                                      style={{
                                                        textDecoration: 'line-through',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                        }}
                                                      >
                                                        {item.itemName}
                                                        {item.brandName && ` | ${item.brandName}`}
                                                        {item.brandName && (
                                                          <img
                                                            src="/img/sessions_symbol.svg"
                                                            className={classes.brandItemSymbol}
                                                            alt="Sessions Brand Item"
                                                          />
                                                        )}
                                                      </span>
                                                      <img
                                                        className={classes.unavailableIcon}
                                                        src="/img/visibility/not-on-menu.svg"
                                                        alt="Not on menu"
                                                        title="Item is not on an active menu"
                                                      />
                                                    </div>
                                                  )}
                                                  {checkIfItemIsOnMenu(item.itemId) &&
                                                    isItemUnavailable(item) && (
                                                      <span className={classes.suggestedItemText}>
                                                        <span
                                                          style={{
                                                            textDecoration: 'line-through',
                                                          }}
                                                        >
                                                          {item.itemName}
                                                        </span>
                                                        <img
                                                          src="/img/visibility/unavailable.svg"
                                                          className={classes.unavailableIcon}
                                                          alt="Unavailable"
                                                          title="Item is unavailable"
                                                        />
                                                      </span>
                                                    )}
                                                </Box>
                                                <IconButton
                                                  size="small"
                                                  className={classes.deleteIcon}
                                                  onClick={() => {
                                                    handleDelete(setFieldValue, index, values);
                                                    setItemsForDeletion(
                                                      itemsForDeletion
                                                        ? [item?.itemId, ...itemsForDeletion]
                                                        : [item?.itemId],
                                                    );
                                                  }}
                                                >
                                                  <MdDelete />
                                                </IconButton>
                                              </Box>
                                            )}
                                          </Field>
                                        </Draggable>
                                      ),
                                  )}
                                </>
                              )}
                            </>
                          </Droppable>
                        )}
                      </DragDropContext>
                    </Box>
                    {upsellGroup && (
                      <AddGroupItems
                        title="Add suggestions"
                        menuItems={eligibleUpsellItems}
                        isDialogOpen={isDialogOpen}
                        handleCloseDialog={handleCloseDialog}
                        checkedItems={upsellGroup.suggestedItemIds}
                        handleOnSave={handleOnSave}
                      />
                    )}
                    {isLessThanThreeWarning && (
                      <Box className={classes.warningBannerMin}>
                        <CustomWarningBanner
                          title="You need to add at least 3 upsells"
                          iconColor="#647F99"
                          backgroundColor="greyLightest"
                          titleFont="smallBold"
                          titleColor="greyDarker"
                          warning="Upsells won’t display during checkout if there are less than 3 items"
                          warningFont="small"
                        />
                      </Box>
                    )}
                  </CardContent>
                </Form>
              </>
            )}
          </Formik>
        </Card>
      </Page>
    )
  );
};

export default withVenue(CheckoutUpsellGroup, '/upsells', [
  clearUpsellGroup,
  clearMenuItems,
  clearVenueSettings,
]);
