import { InfoOutlined } from '@mui/icons-material';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  breakdownContainer: {
    border: '1px solid #F2F2F7',
    marginTop: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
  breakdownRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderBottom: '1px solid #F2F2F7',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  breakdownKey: {
    ...theme.customFonts.small,
    display: 'flex',
    alignItems: 'center',
  },
  breakdownKeyBold: {
    ...theme.customFonts.smallBold,
  },
  deliveryPayoutInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: '#F2F2F7',
    borderRadius: theme.spacing(1),
  },
  deliveryPayoutInfo: {
    ...theme.customFonts.small,
  },
  sectionTitle: {
    ...theme.customFonts.mediumBold,
  },
}));

const PayoutBreakdown = ({ breakdown, bankDetails, type, payoutBreakdownValues, total }) => {
  const classes = useStyles();

  return (
    <Grid container sx={{ mt: 3 }} columnSpacing={2}>
      <Grid item md={6} xs={12}>
        <Typography variant="h2" className={classes.sectionTitle}>
          Payout breakdown
        </Typography>
        <Box className={classes.breakdownContainer}>
          {Object.entries(breakdown).map(([key, value]) => (
            <Box key={key} className={classes.breakdownRow}>
              <Typography className={classes.breakdownKey}>
                {payoutBreakdownValues[key].label}
                {payoutBreakdownValues[key].tooltip && (
                  <Tooltip
                    title={payoutBreakdownValues[key].tooltip}
                    sx={{ backgroundColor: '#636363' }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontSize: '14px',
                          lineHeight: '24px',
                        },
                      },
                    }}
                  >
                    <InfoOutlined sx={{ marginLeft: '4px', width: '16px' }} />
                  </Tooltip>
                )}
              </Typography>
              <Typography className={classes.breakdownKey}>{value}</Typography>
            </Box>
          ))}
          <Box className={classes.breakdownRow}>
            <Typography className={classes.breakdownKeyBold}>Payout</Typography>
            <Typography className={classes.breakdownKeyBold}>{total}</Typography>
          </Box>
        </Box>
      </Grid>
      {bankDetails && (
        <Grid item md={6} xs={12} sx={{ mt: { sm: 3, md: 0 } }}>
          <Typography variant="h2" className={classes.sectionTitle}>
            Bank details
          </Typography>
          <Box className={classes.breakdownContainer}>
            <Box className={classes.breakdownRow}>
              <Typography className={classes.breakdownKey}>Account no.</Typography>
              <Typography className={classes.breakdownKey}>{bankDetails.accountNumber}</Typography>
            </Box>
            <Box className={classes.breakdownRow}>
              <Typography className={classes.breakdownKey}>Sort</Typography>
              <Typography className={classes.breakdownKey}>{bankDetails.sortCode}</Typography>
            </Box>
          </Box>
          {type === 'DELIVERY' && (
            <Box className={classes.deliveryPayoutInfoContainer}>
              <InfoOutlined sx={{ marginRight: '12px' }} />
              <Typography className={classes.deliveryPayoutInfo}>
                Payouts for delivery sales are paid weekly due to how the delivery companies handle
                finances.
              </Typography>
            </Box>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default PayoutBreakdown;
