import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import fetchPayoutApi from '../../api/payout';

export const fetchPayout = createAsyncAction('fetch-payout', fetchPayoutApi);

export const getPayoutState = (state) => state.payout;

const payoutsSlice = createSlice({
  name: 'payout',
  initialState,
  reducers: {
    clearPayout(state) {
      delete state.data;
    },
  },
  extraReducers: { ...createAsyncReducerHandlers(fetchPayout) },
});

export const { clearPayout } = payoutsSlice.actions;
export const { reducer: payout } = payoutsSlice;
