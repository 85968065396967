import React from 'react';
import { Card, Link } from '@mui/material';

import OrderableTable from '../OrderableTable';
import useEndpoint from '../../hooks/useEndpoint';
import { getDeliveryStatements } from '../../store/deliveryStatements/selectors';
import { clearDeliveryStatements, fetchDeliveryStatements } from '../../store/deliveryStatements';
import withVenue from '../../hoc/withVenue';

const valueFormatter = ({ value, valueName }) => {
  switch (valueName) {
    case 'url':
      return (
        <Link
          style={{ textDecoration: 'underline' }}
          target="_blank"
          rel="noopener noreferrer"
          href={value}
          download
        >
          Download
        </Link>
      );
    default:
      return value;
  }
};
const DeliveryStatementsTable = () => {
  const { data: deliveryStatements } = useEndpoint(
    getDeliveryStatements,
    fetchDeliveryStatements(),
  );

  return (
    <Card>
      {deliveryStatements && (
        <OrderableTable
          tableData={deliveryStatements}
          titles={['FILENAME', '']}
          valueFormatter={valueFormatter}
          disableColumnTitles={['']}
        />
      )}
    </Card>
  );
};

export default withVenue(DeliveryStatementsTable, null, clearDeliveryStatements);
