import { createSelector } from '@reduxjs/toolkit';
import { descend, prop, sortWith } from 'ramda';

const venueSort = sortWith([descend(prop('createdAt'))]);

export const getVenuesSettingsState = (state) => state.venueSettings;
export const getVenuePrioritiesState = (state) => state.venuePriorities;
export const getVenueAdditionalChargesState = (state) => state.venueAdditionalCharges;
export const getVenueHideGMVSettingState = (state) => state.venueHideGMVSetting;
export const getVenuesState = (state) => state.venues;
export const getVenueState = (state) => state.venue;
// TODO: Refactor to use an "at least" approach like useRoles.jsx
export const isStandard = (state) => {
  const { venue } = state || {};
  const { data: venueData } = venue || {};
  const isstandard = venueData?.accountType === 'STANDARD' || venueData?.accountType === 'LITE';
  return isstandard;
};
export const isPro = (state) => {
  const { venue } = state || {};
  const { data: venueData } = venue || {};
  const ispro = venueData?.accountType === 'PRO' || venueData?.accountType === 'FRANCHISE';
  return ispro;
};
export const isFranchise = (state) => {
  const { venue } = state || {};
  const { data: venueData } = venue || {};
  const isfranchise = venueData?.accountType === 'FRANCHISE';
  return isfranchise;
};
export const isDeliveryOnly = (state) => {
  const { venue } = state || {};
  const { data: venueData } = venue || {};
  const isdeliveryonly = venueData?.accountType === 'DELIVERY_ONLY';
  return isdeliveryonly;
};

export const isMultivendor = (state) => {
  const { venue } = state || {};
  const { data: venueData } = venue || {};
  return venueData?.children && venueData?.children.length !== 0;
};

export const isBrandUp = (state) => {
  const { venue } = state || {};
  const { data: venueData } = venue || {};
  const isbrandup = venueData?.accountType === 'BRANDUP_LITE';
  return isbrandup;
};
export const isMultiVendor = (state) => {
  const { venue } = state || {};
  const { data: venueData } = venue || {};
  const ismultivendor = !!venueData?.children;
  return ismultivendor;
};

export const getVenues = createSelector(
  getVenuesState,
  (venuesState) => venuesState.data && venueSort(venuesState.data),
);

export const getVenue = createSelector(getVenueState, (venueState) => venueState.data);

export const getDeliveryOnlyVenues = createSelector(getVenuesState, (venuesState) => {
  const { data } = venuesState || {};
  const deliveryOnlyVenues = data.filter((venue) => venue.accountType === 'DELIVERY_ONLY');
  return deliveryOnlyVenues;
});
