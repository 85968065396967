import React from 'react';
import { useParams } from 'react-router';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import withVenue from '../../hoc/withVenue';
import TabContainer from '../../components/TabContainer';
import SettingsPage from './Venue';
import BusinessPage from './Business';
import TeamPage from './Team';
import PaymentsPage from './Payments';
import Page from '../../components/Page';
import BillingPage from './Billing';
import useRoles from '../../hooks/useRoles';
import useEndpoint from '../../hooks/useEndpoint';
import { getBillingState } from '../../store/billing/selectors';
import { clearBilling, fetchBilling } from '../../store/billing';
import { isMultivendor } from '../../store/venues/selectors';

const Settings = () => {
  const { tabname } = useParams();
  const { isOwner, isAdmin } = useRoles();
  const typeMultivendor = useSelector(isMultivendor);
  const { data: subscriptionData } = useEndpoint(getBillingState, fetchBilling());
  const hasCustomer = subscriptionData?.customer;
  const showBilling = isOwner() && hasCustomer;
  const showBusiness = isAdmin() || (isOwner() && !typeMultivendor);

  const titles = ['Venue', 'Users', 'Payments'];
  if (showBilling) titles.push('Billing');
  // add 'Business' to titles array at index 1 if showBusiness is true
  if (showBusiness) titles.splice(1, 0, 'Business');

  const defaultTabName = tabname === 'billing' && !showBilling ? 'payments' : tabname;

  return (
    <Page>
      <Typography style={{ flexGrow: 1 }} variant="h2" component="h1">
        Settings
      </Typography>
      <TabContainer titles={titles} defaultTab={defaultTabName} path="/settings/">
        <SettingsPage />
        {showBusiness && <BusinessPage />}
        <TeamPage />
        <PaymentsPage />
        {showBilling && <BillingPage />}
      </TabContainer>
    </Page>
  );
};

export default withVenue(Settings, null, clearBilling);
