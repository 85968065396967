import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, DialogTitle, IconButton, Typography, Box, Button, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MdClose } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { getErrorMessage } from '../../shared/utils/errors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import {
  deleteImageGallery,
  deleteBrandImageGallery,
  deleteBrandCategoryImage,
  deleteBrandLogo,
  deleteBrandHero,
} from '../../store/imageGallery';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: 'Sen',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontFamily: 'Sen',
    flexGrow: 1,
    paddingBottom: theme.spacing(2),
    borderBottom: '0.5px #f0f4f8 solid',
  },
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
  subtitleText: {
    fontFamily: 'Sen',
    fontSize: '1.2rem',
  },
  blueText: {
    fontFamily: 'Sen',
    color: '#333333',
    fontWeight: 600,
    marginTop: '2rem',
  },
  greyText: {
    fontFamily: 'Sen',
    color: '#656565',
    fontWeight: 600,
    marginTop: '1rem',
  },
  inlinesvg: {
    marginRight: theme.spacing(1),
    height: 15,
  },
  button: {
    marginBottom: theme.spacing(1),
  },
  error: {
    color: 'red',
    borderColor: 'red',
  },
  examplePhoto: {
    width: '30%',
    height: '100%',
    borderRadius: 5,
  },
}));

const PhotoUploadChooser = ({
  handleCloseDialog,
  handleLocalFile,
  handleShowGallery,
  imgUrl,
  hasImage,
  itemId,
  isBrands = false,
  isCategoryImage = false,
  isBrandLogo = false,
  isBrandHero = false,
  isKioskCarouselImage = false,
  brandId,
  menuId,
  enforceImageRequirements = false,
}) => {
  const allowedFileTypes = isCategoryImage || isBrandLogo ? 'image/png' : 'image/jpeg, image/png';
  const classes = useStyles();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const [photoUploadError, setPhotoUploadError] = useState(null);
  const dispatch = useDispatch();

  let title = 'Product Image';
  if (isBrandLogo) {
    title = '';
  }
  if (isCategoryImage) {
    title = 'Category Image';
  }

  const handleCapture = ({ target }) => {
    const file = target.files[0];
    if (file) {
      const maxFileSize = 10 * 1024 * 1024; // 10MB
      if (enforceImageRequirements && file.size > maxFileSize) {
        setPhotoUploadError('File size exceeds the 10MB limit.');
        return;
      }

      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const { width, height } = img;
        if (
          enforceImageRequirements &&
          (width < 1200 || width > 6000 || height < 800 || height > 6000)
        ) {
          setPhotoUploadError(
            // eslint-disable-next-line max-len
            `Image dimensions must be between 1200px ≤ Width ≤ 6000px and 800px ≤ Height ≤ 6000px. Your image is ${width}x${height}.`,
          );
          return;
        }

        URL.revokeObjectURL(img.src);
        handleLocalFile(file);
      };
    }
  };

  const deletePhoto = useCallback(async () => {
    try {
      if (isBrands) {
        await dispatch(deleteBrandImageGallery({ itemId }));
      } else if (isCategoryImage) {
        await dispatch(deleteBrandCategoryImage({ itemId, menuId }));
      } else if (isBrandHero) {
        await dispatch(deleteBrandHero({ brandId }));
      } else if (isBrandLogo) {
        await dispatch(deleteBrandLogo({ brandId }));
      } else {
        await dispatch(deleteImageGallery({ itemId }));
      }
      showSuccessNotification('The image has been removed');
      handleCloseDialog(true);
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  }, [
    isBrands,
    isCategoryImage,
    isBrandLogo,
    isBrandHero,
    showSuccessNotification,
    handleCloseDialog,
    dispatch,
    itemId,
    menuId,
    brandId,
    showErrorNotification,
  ]);

  return (
    <>
      <DialogTitle
        onClose={() => {
          handleCloseDialog(false);
        }}
      >
        <Typography mt={3} align="center" className={classes.title}>
          {title}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => {
            handleCloseDialog(false);
          }}
          size="large"
        >
          <MdClose />
        </IconButton>
      </DialogTitle>
      {hasImage ? (
        <Box ml={8} mr={8} mt={4} mb={4}>
          <img src={imgUrl} alt="item preview" width="100%" />
        </Box>
      ) : (
        <>
          <Box ml={8} mr={8} mb={4}>
            <Typography variant="h4" className={classes.subtitleText}>
              Photo Guidelines
            </Typography>
            <Typography variant="body2" mt={4} className={classes.blueText}>
              Make sure the item is
            </Typography>
            <Typography variant="body2" mt={4} className={classes.greyText}>
              <img
                src="/img/uploader/food-course.svg"
                alt="Brightly lit"
                className={classes.inlinesvg}
              />
              Ready to serve
            </Typography>
            <Typography variant="body2" mt={4} className={classes.greyText}>
              <img
                src="/img/uploader/brightly-lit.svg"
                alt="Brightly lit"
                className={classes.inlinesvg}
              />
              Brightly lit &amp; in focus
            </Typography>
            <Typography variant="body2" mt={4} className={classes.greyText}>
              <img
                src="/img/uploader/large-centered.svg"
                alt="Brightly lit"
                className={classes.inlinesvg}
              />
              Large and centered
            </Typography>
            <Typography variant="body2" mt={4} className={classes.blueText}>
              Example photos
            </Typography>
            <Box display="flex" justifyContent="space-between" mt={2}>
              <img
                className={classes.examplePhoto}
                src="https://assets.dev.sessions.systems/servedup/image-gallery/SPIRITS/JAGER/Jagerbomb_2.jpg"
                alt="example 1"
              />
              <img
                className={classes.examplePhoto}
                // eslint-disable-next-line max-len
                src="https://assets.dev.sessions.systems/servedup/image-gallery/SPIRITS/GORDONS/Gordons_3_NoLiscense.jpg"
                alt="example 2"
              />
              <img
                className={classes.examplePhoto}
                // eslint-disable-next-line max-len
                src="https://assets.dev.sessions.systems/servedup/image-gallery/BEERS%20&%20CIDERS/GUINNESS/Guinness_1.jpg"
                alt="example 3"
              />
            </Box>
          </Box>
        </>
      )}
      <Box ml={8} mr={8} mb={4}>
        <Typography variant="body2">
          By uploading pictures to Sessions Serve you are accepting our{' '}
          <Link
            className={classes.blueText}
            target="_blank"
            href="https://serve.sessions.co.uk/termsandconditions"
          >
            terms &amp; conditions
          </Link>
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" mr={8} ml={8} mb={8}>
        <Button variant="contained" color="primary" className={classes.button} component="label">
          Upload photo
          <input type="file" hidden onChange={handleCapture} accept={allowedFileTypes} />
        </Button>
        {photoUploadError && (
          <Alert sx={{ marginBottom: 1 }} severity="error">
            {photoUploadError}
          </Alert>
        )}
        {!isCategoryImage && !isBrandLogo && !isKioskCarouselImage && !isBrands && (
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={handleShowGallery}
          >
            Choose from our gallery
          </Button>
        )}

        {imgUrl && !isKioskCarouselImage ? (
          <Button
            variant="outlined"
            className={`${classes.button} ${classes.error}`}
            onClick={deletePhoto}
          >
            Delete photo
          </Button>
        ) : (
          <Button
            variant="outlined"
            className={`${classes.button} ${classes.error}`}
            onClick={() => {
              handleCloseDialog(false);
            }}
          >
            Cancel
          </Button>
        )}
      </Box>
    </>
  );
};

PhotoUploadChooser.propTypes = {
  handleCloseDialog: PropTypes.func.isRequired,
};

export default PhotoUploadChooser;
